import { type GameId } from '@/globalVariables'
import type Reward from './Reward'

export enum TournamentState {
  Open = 'open',
  Drawing = 'drawing',
  Running = 'running',
  Finished = 'finished',
  Evaluating = 'evaluating',
  Cancelled = 'cancelled',
}

export enum TournamentUserState {
  Joined = 'joined',
  Running = 'running',
  Finished = 'finished',
  Cancelled = 'cancelled',
  Locked = 'locked',
}

export interface TournamentButtonNotification {
  type: 'cta' | 'number'
  value?: number
}

export interface Tournament {
  id: number
  discipline_id: number
  name: string
  attribute: number
  start_date: string
  end_date: string
  evaluate_end_date: string | null
  benefit: boolean
  rewards: Reward[]
  tournament_state: TournamentState
  user_state: TournamentUserState | null
  position: number | null
  rounds_total: number
  play_with_mulligans: boolean
  timer_ends_in: number
  mechanic: string | null
  button_notification?: TournamentButtonNotification
}

export interface TournamentMetaRaw {
  free_daily_joins_limit: number
  free_daily_joins: number
  join_gems_price: number
  join_starts_price: number
  play_mulligans_price: number
  play_mulligans_round?: number
  play_with_mulligans?: boolean
  tournament_points: number
  mulligans: number
  showAcceptStatute?: boolean
}

export interface TournamentMeta {
  freeDailyJoinsLimit: number
  freeDailyJoins: number
  joinGemsPrice: number
  joinStartsPrice: number
  playMulligansPrice: number
  playMulligansRound: number
  playWithMulligans: boolean
  tournamentPoints: number
  mulligans?: number
  showAcceptStatute: boolean
}

interface TournamentSignal {
  tournament_id: number
  city: string
  game_discipline_id: number
  status: TournamentState
}

export interface TournamentSignalResponse {
  game_id: GameId
  // pride bud tournament alebo tasks
  tournament?: TournamentSignal
  tasks?: {
    reloadReportNotifications?: boolean
  }
}
