<template>
  <div :id="mapId" ref="parent" />
  <map-event-handler v-if="isMainMap" />
  <map-board-games-event-handler v-if="isMainMap" />
  <map-piggy-bank-handler v-if="isMainMap" />
  <map-special-tournament-handler v-if="isMainMap" />
  <map-notifications-handler />
</template>

<script lang="ts">
import MapEventHandler from './MapEventHandler.vue'
import MapBoardGamesEventHandler from './MapBoardGamesEventHandler.vue'
import MapPiggyBankHandler from './MapPiggyBankHandler.vue'
import MapSpecialTournamentHandler from './MapSpecialTournamentHandler.vue'
import MapNotificationsHandler from './MapNotificationsHandler.vue'
import { launch } from '@/map-phaser-new'
import { CHANGE_SCENE, SCENES_KEYS, MECHANIC_MAP_ITEMS } from '@/map-phaser-new/constants'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'

import { mapState, mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'

interface ComponentData {
  mapId: string
}

export default defineComponent({
  name: 'MapView',
  components: {
    MapEventHandler,
    MapBoardGamesEventHandler,
    MapPiggyBankHandler,
    MapSpecialTournamentHandler,
    MapNotificationsHandler,
  },
  data(): ComponentData {
    return {
      mapId: 'map-container',
    }
  },
  computed: {
    ...mapState(usePhaserGameIntegrationStore, {
      phaserEventEmitter: 'getPhaserEventEmitter',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
    }),
    isMainMap(): boolean {
      return this.$route.meta?.activeScene === SCENES_KEYS.MainMapScene
    },
  },
  watch: {
    $route: {
      handler(to: RouteLocationNormalized): void {
        if (to.name === 'ClubsMap' && !to.params.clubId) return

        const activeScene: string = (to.meta?.activeScene as string) ?? SCENES_KEYS.MainMapScene
        this.phaserEventEmitter?.emit(CHANGE_SCENE, activeScene)
      },
      immediate: true,
    },
  },
  mounted() {
    launch()
    this.iPadWebGLFix()
    // TAB INACTIVITY handler
    document.onvisibilitychange = (): void => {
      if (document.visibilityState === 'visible') {
        this.refresProgressTimers()

        if (this.hasMechanic(MECHANIC_MAP_ITEMS)) {
          this.refreshMainMapRewardedItems()
        }
      }
    }
  },
  methods: {
    ...mapActions(usePhaserGameIntegrationStore, [
      'refresProgressTimers',
      'refreshMainMapRewardedItems',
    ]),
    iPadWebGLFix() {
      if (!window?.navigator?.userAgent.includes('iPad')) return

      const handleWebGLContextLost = (e) => {
        e.preventDefault()
        const reloadCount = localStorage.getItem('reload-count')
        if (!reloadCount) {
          localStorage.setItem('reload-count', '1')
          window.location.reload()
        }
      }

      // add global webglcontextlost event listener
      window.addEventListener('webglcontextlost', handleWebGLContextLost)

      // Add canvas listener
      const parent = this.$refs.parent as HTMLDivElement
      parent.lastChild?.addEventListener('webglcontextlost', handleWebGLContextLost)
    },
  },
})
</script>

<!-- here is eligible to use style without scoped -->
<style lang="scss">
#map-container > div {
  box-shadow: $bottom-shadow;
}
</style>
