import {
  CREATE_SPECIAL_TOURNAMENT_OSRA,
  DESTROY_SPECIAL_TOURNAMENT_OSRA,
} from '@/map-phaser-new/constants'
import { SpecificObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import { SpecialTournamentOsra } from '@/map-phaser-new/models/objects/specialTournamentOsra'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { isWsm } from '@/plugins'

import type {
  AdditionalConfigsInterface,
  ExclamationNotificationConfigInterface,
  SpecialTournamentOsraConfigInterface,
} from '@/map-phaser-new/interfaces'
import { DISCIPLINE_ID } from '@/globalVariables'

export class SpecialTournamentOsraHandler extends SpecificObjectHandler {
  private osraInstance: SpecialTournamentOsra

  constructor() {
    super()
  }

  public async setUp(
    activeScene: Phaser.Scene,
    osraConfig: SpecialTournamentOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    return new Promise((resolve: CallableFunction): void => {
      this.createOsra(activeScene, osraConfig, additionalConfigs.exclamationNotification)

      const eventEmitter = usePhaserGameIntegrationStore().getPhaserEventEmitter
      this.addOsraActionsToEmitter(
        eventEmitter,
        activeScene,
        osraConfig,
        additionalConfigs.exclamationNotification,
      )

      resolve()
    })
  }

  private createOsra(
    activeScene: Phaser.Scene,
    config: SpecialTournamentOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.osraInstance) return

    if (!isWsm) return
    const unlockedDisciplinesIds = useDisciplineStore().getUnlockedDisciplinesIds
    if (!unlockedDisciplinesIds.includes(DISCIPLINE_ID.biathlon)) return

    this.osraInstance = new SpecialTournamentOsra(config.positionX, config.positionY)
    this.osraInstance.addToMap(activeScene, config, notificationConfig)
  }

  private destroyOsra(activeScene: Phaser.Scene): void {
    if (!this.osraInstance) return

    this.osraInstance.removeOsra(activeScene)
    this.osraInstance = null
  }

  private addOsraActionsToEmitter(
    eventEmitter: Phaser.Events.EventEmitter,
    activeScene: Phaser.Scene,
    osraConfig: SpecialTournamentOsraConfigInterface,
    notificationConfig: ExclamationNotificationConfigInterface,
  ): void {
    if (this.osraInstance) return

    eventEmitter.on(
      CREATE_SPECIAL_TOURNAMENT_OSRA,
      (): void => {
        this.createOsra(activeScene, osraConfig, notificationConfig)
      },
      this,
    )

    eventEmitter.on(
      DESTROY_SPECIAL_TOURNAMENT_OSRA,
      (): void => {
        this.destroyOsra(activeScene)
      },
      this,
    )
  }
}
