import type { BubbleNotificationConfigInterface } from '@/map-phaser-new/interfaces'
import {
  CLUB,
  CAREER,
  TRAINING_HALL,
  SHOP,
  LABORATORY,
  TOURNAMENTS,
} from '@/map-phaser-new/constants'

export const bubbleNotification: BubbleNotificationConfigInterface = {
  buildings: [CAREER, CLUB, SHOP, TRAINING_HALL, LABORATORY, TOURNAMENTS],
  textureKey: 'bubbleNotification',
  depth: 8,
  origin: 0.5,
  scale: 0.7,
  xCorrectionValue: 1.5,
  yCorrectionValue: -30,
  animationYIndent: '+15',
  animationYEase: 'Back',
  yoyo: true,
  duration: 850,
  repeat: -1,
  yoyoHold: 3000,
}
