import type { RewardedItemsPositionsInterface } from '@/map-phaser-new/interfaces'

export const rewardedItemsPositionsData: RewardedItemsPositionsInterface[] = [
  { x: 1142.5, y: 1207 },
  { x: 1092, y: 1030 },
  { x: 920, y: 995 },
  { x: 925, y: 1110 },
  { x: 1015, y: 680 },
  { x: 1210, y: 855 },
  { x: 1430, y: 1000 },
  { x: 1490, y: 1060 },
  { x: 1465, y: 615 },
  { x: 1567, y: 640 },
  { x: 2050, y: 845 },
  { x: 2370, y: 867 },
  { x: 2461, y: 903 },
  { x: 2461, y: 767 },
  { x: 2680, y: 570 },
  { x: 3100, y: 880 },
  { x: 3210, y: 1040 },
  { x: 3135, y: 1330 },
  { x: 3030, y: 1300 },
  { x: 2950, y: 1255 },
  { x: 2910, y: 1360 },
  { x: 2760, y: 1590 },
  { x: 2785, y: 1680 },
  { x: 2190, y: 1690 },
  { x: 2180, y: 1600 },
]
