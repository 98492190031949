import type { MapImageConfigInterface } from '@/map-phaser-new/interfaces'

export const mapImageConfig: MapImageConfigInterface = {
  textureKey: 'clubsMap',
  mapOverlaysConfig: [
    {
      textureKey: 'club-overlay',
      depth: 3,
    },
  ],
}
