import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubHelicopterRoundPath: PathObjectInterface = {
  mainPath: { x: 3882, y: 954 },
  spline: [
    3882, 954, 3247, 940, 2527, 781, 2059, 716, 1720, 659, 1514, 661, 1403, 730, 1370, 810, 1315,
    692, 1043, 695, 770, 937, 748, 1309, 1199, 1669, 2008, 1724, 3055, 1619, 3386, 1470, 3671, 1314,
    3849, 1295, 3879, 1295,
  ],
}
