import { logErrorEndpoint } from '@/globalVariables'
import { getValidationToken, isFlutterApp, sendToFlutter, toArray } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import { useUserStore } from '@/store/pinia/userStore'
import { defineStore } from 'pinia'

interface CoreStoreState {
  axiosErrors: string[]
  isOffline: boolean
  loadingScreen: boolean
  componentLoading: boolean
  simulation: boolean
  isMinigame: boolean
  isFirstTimeClicked: boolean
  hideMap: boolean
}

interface CustomError {
  type: string
  message: string
}

export const useCoreStore = defineStore('coreStore', {
  state: (): CoreStoreState => ({
    axiosErrors: [] as string[],
    isOffline: false,
    loadingScreen: null,
    componentLoading: false,
    simulation: false,
    isMinigame: false,
    isFirstTimeClicked: false,
    hideMap: false,
  }),
  getters: {
    getAxiosErrors(): string[] {
      return this.axiosErrors ?? []
    },
    hasBadCredentials(): boolean {
      return this.axiosErrors?.includes('$ERRORMSG$error.badCredentials') ?? false
    },
    isInvalidEmail(): boolean {
      return this.axiosErrors?.includes('$ERRORMSG$error.errorEmailInvalidFormat') ?? false
    },
    // FIXME: Toto sa uz v novom login flow nepouziva.
    emailExists(): boolean {
      return this.axiosErrors?.includes('$ERRORMSG$error.emailExists') ?? false
    },
    emailRegisteredElsewhere(): boolean {
      return this.axiosErrors?.includes('$ERRORMSG$error.emailRegisteredElsewhere') ?? false
    },
    emailAlreadySent(): boolean {
      return this.axiosErrors?.includes('$ERRORMSG$error.emailAlreadySent') ?? false
    },
    tournamentIsFull(): boolean {
      return this.axiosErrors?.includes('$ERRORMSG$error.fullTournament') ?? false
    },
    tournamentEnded(): boolean {
      return this.axiosErrors?.includes('$ERRORMSG$error.tournamentEnded') ?? false
    },
    getOffline(): boolean {
      return this.isOffline
    },
    isLoadingScreen(): boolean {
      return this.loadingScreen
    },
    isComponentLoading(): boolean {
      return this.componentLoading
    },
    isSimulation(): boolean {
      return this.simulation
    },
    forceHideMap(): CoreStoreState['hideMap'] {
      return this.hideMap
    },
  },
  actions: {
    addAxiosError(errors: string[] | string): void {
      // naplni sa stav chybovymi hlaskami vratenymi z axiosu - zobrazi sa modal okno s chybou
      this.axiosErrors = toArray(errors)
    },
    removeAxiosErrors(): void {
      // vymaze sa stav s axios chybami - modal okno s chybami sa schova
      this.axiosErrors = []
    },
    setIsMinigame(status: boolean): void {
      this.isMinigame = status
    },
    async postError(error: CustomError): Promise<void> {
      const token = getValidationToken('token')
      if (token && !this.isOffline) {
        const newErr = { user_game_id: token, ...error }
        await internalAxios.post<{}, { error_logged: boolean }>(logErrorEndpoint, newErr)
      }
    },
    setOffline(offline: boolean): void {
      this.isOffline = offline
    },
    setSimulation(status: boolean): void {
      this.simulation = status
    },
    setLoadingScreen(status: boolean): void {
      if (status) {
        const loadingStart = Date.now()
        if (!sessionStorage.getItem('loadingStart')) {
          sessionStorage.setItem('loadingStart', loadingStart.toString())
        }
      } else {
        sendToFlutter('{\r\n  "event": "loadingDone"\r\n}')
        const loadingEnd = Date.now()
        if (sessionStorage.getItem('loadingStart') && !sessionStorage.getItem('loadingEnd')) {
          sessionStorage.setItem('loadingEnd', loadingEnd.toString())

          const ssLoadingStart = sessionStorage.getItem('loadingStart')
          const ssLoadingEnd = sessionStorage.getItem('loadingEnd')
          const loadingDuration = (Number(ssLoadingEnd) - Number(ssLoadingStart)) / 1000

          sessionStorage.setItem('loadingDuration', `${loadingDuration} seconds`)
          this.postError({
            type: 'loading-duration',
            message: String(loadingDuration),
          })
        }

        const userStore = useUserStore()
        if (isFlutterApp() && userStore.getMusicVolume) {
          sendToFlutter('{\r\n  "event": "setMusic",\r\n "enabled": true\r\n}')
        }
      }
      this.loadingScreen = status
    },
    setComponentLoading(status: boolean): void {
      this.componentLoading = status
    },
    setFirstTimeClick(status: boolean): void {
      this.isFirstTimeClicked = status
    },
    setForceHideMap(status: boolean): void {
      this.hideMap = status
    },
  },
})

export type CoreStore = ReturnType<typeof useCoreStore>
