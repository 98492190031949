<template>
  <div class="price-section text-texts-standard-default text-40 flex items-center relative">
    <div class="price-section-before absolute">
      <app-striketrough v-show="oldPrice">
        <p class="text-texts-standard-additional text-30">
          {{ oldPrice }}
        </p>
      </app-striketrough>
    </div>
    <div class="text-40 relative m-auto flexing price-section-value font-bold">
      <div class="price-section-left relative" />
      <app-svg-icon icon-name="bracket" :height="decorationHeight" :fill="decorationColor" />
      <slot />
      <app-svg-icon
        icon-name="bracket"
        :height="decorationHeight"
        :fill="decorationColor"
        :vertical-flip="true"
      />
      <div class="price-section-right relative" />
      <template v-if="exchangePrice.length">
        <div class="font-normal rate text-32">( {{ exchangePrice }} )</div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import AppStriketrough from '@/components/GlobalComponents/AppStriketrough.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppPrice',
  components: {
    AppStriketrough,
  },
  props: {
    oldPrice: {
      type: String,
      default: '',
    },
    exchangePrice: {
      type: String,
      default: '',
    },
    decorationHeight: {
      type: String,
      default: '',
    },
    decorationColor: {
      type: String,
      default: '',
    },
  },
})
</script>

<style lang="scss" scoped>
.rate {
  margin-left: 1rem;
}

.price-section {
  width: auto;
  height: 2.0625rem;
  line-height: 2.0625rem;

  &-before {
    // This ensures that the price does not wrap and maintains a single line
    // Fixing issues with wider price overflowing
    white-space: nowrap;
    top: -2.4rem;
    left: 50%;
    transform: translate(-50%);
  }

  &-value {
    &:before {
      content: '';
      margin-right: 1rem;
      font-style: normal;
    }

    &:after {
      content: '';
      margin-left: 1rem;
      font-style: normal;
    }
  }
}
</style>
