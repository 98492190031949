<template>
  <div data-special-tournament-handler />
</template>

<script lang="ts">
import { DISCIPLINE_ID } from '@/globalVariables'
import { CREATE_SPECIAL_TOURNAMENT_OSRA } from '@/map-phaser-new/constants'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapSpecialTournamentHandler',
  computed: {
    ...mapState(useTutorialStore, { isTutorial: 'getIsTutorial' }),
    ...mapState(useResponseTaskStore, { mechanics: 'getMechanics' }),
    ...mapState(usePhaserGameIntegrationStore, { phaserEventEmitter: 'getPhaserEventEmitter' }),
    ...mapState(useDisciplineStore, {
      getUnlockedDisciplinesIds: 'getUnlockedDisciplinesIds',
    }),
  },
  watch: {
    mechanics: {
      handler(): void {
        this.createSpecialTournamentOsra()
      },
      deep: true,
    },
  },
  async created(): Promise<void> {
    await this.createSpecialTournamentOsra()
  },
  mounted(): void {
    document.addEventListener('visibilitychange', this.onVisibilityChange, { passive: true })
  },
  beforeUnmount(): void {
    document.removeEventListener('visibilitychange', this.onVisibilityChange)
  },
  methods: {
    async onVisibilityChange(): Promise<void> {
      if (document.visibilityState === 'hidden') return
      await this.createSpecialTournamentOsra()
    },
    async createSpecialTournamentOsra(): Promise<void> {
      if (this.isTutorial) return
      if (!this.$isWsm) return
      if (!this.getUnlockedDisciplinesIds.includes(DISCIPLINE_ID.biathlon)) return

      this.phaserEventEmitter?.emit(CREATE_SPECIAL_TOURNAMENT_OSRA)
    },
  },
})
</script>
