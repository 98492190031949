<template>
  <div id="career-task-boss" class="career-task-boss" data-cy="career-task-boss">
    <main>
      <div class="table-row-title">
        <div class="row flexing">
          <p class="text-texts-standard-additional text-28 uppercase">
            {{ $t('career.difficulty') }}
          </p>
        </div>
        <div class="row flexing">
          <p class="text-texts-standard-additional text-28 uppercase">
            {{ $t('career.goal') }}
          </p>
        </div>
        <div class="row-2x flexing">
          <p class="text-texts-standard-additional text-28 uppercase">
            {{ $t('common.reward') }}
          </p>
        </div>
        <div class="row flexing">
          <p class="text-texts-standard-additional text-28 uppercase">
            {{ $t('career.state') }}
          </p>
        </div>
      </div>
      <aside v-for="(item, index) in quest.parameters.target_value" :key="index">
        <div
          class="table-row-content"
          :class="[
            { 'green-bg border-done': quest.stats.currentValue >= index },
            {
              'locked-bg table-row-content-border-locked':
                isSpecialBossFight && quest.bossFightRoundUnlocked < index,
            },
          ]"
        >
          <div
            v-if="isSpecialBossFight && quest.bossFightRoundUnlocked < index"
            class="locked-content"
          >
            <div class="w-full h-full flexing">
              <div class="icon-lock-sm locked-content-icon" />
              <p class="text-texts-standard-default text-28 bold">
                {{ $t('career.locked') }}
              </p>
            </div>
          </div>
          <div class="row flexing">
            <div v-for="(star, indexStar) in starsCount(index)" :key="indexStar">
              <div :class="star ? 'icon-star-full-56 ' + star : 'icon-star-empty-56'" />
            </div>
          </div>
          <div class="row flexing">
            <p
              v-if="isSpecialBossFight"
              class="text-texts-standard-default text-32 bold leading-tight"
            >
              {{ getSpecialBossFightGoal(Number(index)) }}
            </p>
            <p v-else class="text-texts-standard-default text-36 uppercase font-bold">
              {{
                +index === 1
                  ? $replacePlaceholder($t('career.top'), '%s', item)
                  : $t('newCareerQuest.' + quest.parameters?.id + '_' + index)
              }}
            </p>
            <div
              v-if="getQuestStatus(index) === questBossProgress.oneStars"
              class="icon-done-md m-4"
            />
          </div>
          <div
            class="row-2x flexing"
            :class="{
              'row-opacity':
                getQuestStatus(index) === questBossProgress.oneStars && !isSpecialBossFight,
            }"
          >
            <rewards
              reward-id="career-task-rewards"
              :reward-data="
                formatedData.filter(
                  (reward: BossFightFormattedReward): boolean =>
                    reward.bossfightThreshold === Number(index),
                )
              "
              :icon-size="56"
              :has-unlocks="index === 1"
              :unlock-id="quest.parameters.order"
              :career-id="careerId"
              :unlock-item="unlockItem"
              :is-font-bold="false"
            />
          </div>
          <div class="row flex justify-end">
            <div class="row-skew flexing">
              <p
                :class="
                  'text-24 uppercase font-bold ' +
                  (getQuestStatus(index) === questBossProgress.oneStars
                    ? 'text-texts-standard-upgrade'
                    : 'text-36 text-texts-standard-default')
                "
              >
                {{ getClaimedStatus(index) }}
              </p>
            </div>
            <div class="row-skew-strip" />
          </div>
        </div>
      </aside>
    </main>
  </div>
</template>

<script lang="ts">
import Rewards from '@/components/Rewards.vue'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'
import type { Nullable } from '@/interfaces/utils'
import type { CareerQuest, QuestReward } from '@/interfaces/Quest'
import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'

interface BossFightFormattedReward {
  bossfightThreshold: number
  type: string
  value: number
}

interface ComponentData {
  stars: Record<string, number>
  routeId: string
  questBossProgress: Record<string, number>
}

export default defineComponent({
  name: 'CareerTaskBoss',
  components: {
    Rewards,
  },
  props: {
    quest: {
      type: Object as PropType<Nullable<CareerQuest>>,
      default: () => null,
    },
    careerId: {
      type: String,
      default: '',
    },
    unlockItem: {
      type: Object as PropType<Nullable<CareerUnlockItem>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      stars: {
        1: 3,
        2: 2,
        3: 1,
      },
      routeId: '',
      questBossProgress: {
        oneStars: 1,
        twoStars: 2,
        threeStars: 3,
      },
    }
  },

  computed: {
    formatedData(): BossFightFormattedReward[] {
      const rewardsArray = []

      if (this.quest && this.quest.rewards) {
        this.quest.rewards.map((reward: QuestReward): BossFightFormattedReward[] => {
          rewardsArray.push({
            bossfightThreshold: reward.bossfightThreshold,
            type: reward.name === 'grand_prize' ? `gp-${reward.rarity}` : reward.name,
            value: reward.amount,
          })

          return rewardsArray
        })

        if (rewardsArray.length > 0) return rewardsArray
      }

      return []
    },
    isSpecialBossFight(): boolean {
      return this.quest.parameters.boss_fight_type_rounds
    },
  },
  watch: {
    $route(route: RouteLocationNormalized): void {
      this.routeId = route.params?.task ? route.params.task.toString() : ''
    },
  },

  created(): void {
    this.routeId = this.$route.params?.task ? this.$route.params.task.toString() : ''
  },
  methods: {
    getSpecialBossFightGoal(item: number): string {
      if (item === 1) return this.$t('career.winQuarterfinal')
      if (item === 2) return this.$t('career.winSemifinal')
      if (item === 3) return this.$t('career.winFinal')
      else return ''
    },
    starsCount(count: number): Record<string, boolean> | undefined {
      const stars = {}
      for (let index = 1; index <= 3; index++) {
        if (index <= count) {
          stars[index] = true
        } else {
          stars[index] = false
        }
      }
      return stars
    },
    getQuestStatus(index: string | number): number | void {
      if (this.quest.stats == null) return

      if (
        this.quest.stats.rewardsClaimed &&
        Number(this.quest.stats.rewardsClaimed) >= Number(index)
      ) {
        return this.questBossProgress.oneStars
      }

      if (Number(this.quest.stats.bossFightTreshold) >= Number(index)) {
        return this.questBossProgress.twoStars
      }

      return this.questBossProgress.threeStars
    },
    getClaimedStatus(index: number): string {
      return this.getQuestStatus(index) === this.questBossProgress.oneStars
        ? this.$t('career.rewardTaken')
        : this.getQuestStatus(index) === this.questBossProgress.twoStars
          ? this.$t('career.notTaken')
          : '-'
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.career-task-boss {
  height: 100%;
  background: url($path-career + 'detail/boss_fight_bg.avif') center bottom no-repeat;
  background-size: cover;

  main {
    padding: 0.75rem 1.438rem;

    .table-row-title,
    .table-row-content {
      display: flex;

      .row {
        width: 25%;
        position: relative;

        .text-red {
          color: #e81414;
        }
      }

      .row-2x {
        width: 50%;
      }

      .unlock-next-task-plus {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        margin-right: 1rem;
      }

      .icon-shield {
        width: 3rem;
        height: 3rem;
      }

      .locked-content {
        position: absolute;
        width: 96.7%;
        height: 5.625rem;
        background: rgba(21, 35, 53, 0.8);
        z-index: 2;

        &-icon {
          background-size: 100% 100%;
          margin-right: 1.5rem;
        }
      }
    }

    .table-row-title {
      background-color: #1a3042;
    }

    .table-row-content-border-locked {
      border: solid 0.063rem rgba(21, 35, 53, 0.8) !important;
    }
    .table-row-content {
      border-style: solid;
      border-width: 0.125rem;
      margin: 0.75rem 0;
      @if $isWsm {
        background: rgba(65, 116, 145, 0.7);
        border-color: #5883a3;
      }
      @if $isSsm {
        background: rgba(#525479, 0.7);
        border-color: #7477a4;
      }

      &.border-done {
        border-style: solid;
        border-width: 0.125rem;
        @if $isWsm {
          border-color: #3fe443;
        }
        @if $isSsm {
          border-color: #0ee7cc;
        }
      }

      .row {
        height: 5.625rem;

        .row-skew {
          background: rgba(21, 76, 118, 0.5);
          clip-path: polygon(5.8% 0%, 100% 0%, 100% 100%, 0% 100%);
          height: 100%;
          width: 91%;
          @if $isWsm {
            background: rgba(21, 76, 118, 0.5);
          }
          @if $isSsm {
            background: rgba(#353958, 0.5);
          }
        }
      }

      .row-skew-strip {
        width: 1.625rem;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: skew(-8deg);
        @if $isWsm {
          background: rgba(21, 76, 118, 0.5);
        }
        @if $isSsm {
          background: rgba(#353958, 0.5);
        }
      }
      .icon-star-full-56 {
        margin: 0.5rem 0.2rem 0 0.2rem;
      }
    }

    .green-bg {
      @if $isWsm {
        background: rgba(21, 133, 110, 0.7);
      }
      @if $isSsm {
        background: rgba(#07a08d, 0.5);
      }
    }

    .row-opacity {
      opacity: 0.6;
    }
  }
}
</style>
