import type { ConnectAccountOsraConfigInterface } from '@/map-phaser-new/interfaces'

export const connectAccountOsra: ConnectAccountOsraConfigInterface = {
  textureKey: 'link_account_image',
  depth: 1,
  origin: 0.5,
  useHandCursor: true,
  appMessage: '{\r\n  "event": "connectAccount"\r\n}',
  positionX: 1424,
  positionY: 1328,
  notificationX: 1424,
  notificationY: 1208,
}
