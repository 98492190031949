import { PROD } from '@/globalVariables'
import type EventInfoConfigApiResponse from '@/interfaces/responses/events/EventInfoConfigApiResponse'
import { eventTranslations } from '@/languages/eventLang'
import { translate } from '@/plugins/translate'
import type { App, Plugin } from 'vue'

type SupportedEventTranslationKeys = EventInfoConfigApiResponse['events'][0]['event_type']

let eventTranslationKey: SupportedEventTranslationKeys

export const initEventTranslations = (
  eventType: SupportedEventTranslationKeys,
  force: boolean = false,
): void => {
  if (!eventType) return
  if (eventTranslationKey && !force) return
  if (!(eventType in eventTranslations)) throw new Error('Unsupported event type')
  eventTranslationKey = eventType
}

export const te = (translationKey: string): ReturnType<typeof translate> => {
  if (!eventTranslationKey) throw Error('Init event translations before usage')
  const foundTranslationKey =
    eventTranslations[eventTranslationKey][translationKey] ||
    eventTranslations.default[translationKey]
  if (foundTranslationKey === '') return ''
  if (!foundTranslationKey) {
    const key = `event['${eventTranslationKey}' || 'default'].${translationKey}`
    console.error(`Translation for event key "${key}" is missing`)
    return ''
  }
  return translate(foundTranslationKey)
}

export const translateEventPlugin: Plugin = {
  install(app: App): void {
    app.config.globalProperties.$te = te
  },
}
