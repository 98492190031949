import {
  ADMIN,
  DEV,
  EVENT_PASS,
  MULTIPLICATOR_OFF_STATE,
  OFFER_STATES,
  PROD,
  QA,
  ROOKIE_PASS,
  SUPER_ADMIN,
  UAT,
  careerQuestFastForwardEndpoint,
  userConsentEndpoint,
  userFastForwardExperienceEndpoint,
  userLevelUpEndpoint,
  userProfileAvatars,
  userProfileEndpoint,
  userProfileUserEndpoint,
  userSystemConfigEndpoint,
} from '@/globalVariables'
import { convertNameValue } from '@/helpers/arrayNameValueConverter'
import { musicTheme, sfxSound, sendToFlutter } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import { isMobile as isMobilePlugin } from '@/plugins'
import { translate } from '@/plugins/translate'
import { defineStore } from 'pinia'
import {
  SystemConfigNames,
  type UserData,
  type Stats,
  type ClubStats,
  type Avatar,
  type Background,
  type UpdatedAvatar,
  type UpdatedBackgrounds,
  type UserAttributes,
  type NewValues,
  type UserState,
} from '@/interfaces/User'
import type {
  Passes,
  SystemConfig,
  UserAvatarInterface,
  UserLevelUpInterface,
  UserProfileEndpointInterface,
  UserProfile,
  UserSystemConfigInterface,
} from '@/interfaces/User'
import type { Multiplicator, MultiplicatorItem } from '@/types/userProfile'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import type { NameValueString } from '@/interfaces/Global'
import type Reward from '@/interfaces/Reward'
import type ConsentApiResponse from '@/interfaces/responses/consent/ConsentApiResponse'
import { ConsentType } from '@/enums/Consent'
import { ApiService } from '@/services/ApiService'

export const useUserStore = defineStore('userStore', {
  state: (): UserState => ({
    userState: {} as UserProfile,
    notShowConfirm: false,
    hasToken: false,
    avatars: null as UpdatedAvatar[],
    backgrounds: [] as UpdatedBackgrounds[],
    allowedENVs: [DEV, QA, UAT, PROD],
    showAccountConnectedPopup: false,
    isSoundEnabled: 0,
    isMusicEnabled: 0,
    gdprPageShowed: false,
    gdprCurrentVersion: '',
  }),
  getters: {
    getUserId(): string {
      return this.userState.user_id
    },
    getAttributes(): UserAttributes {
      return this.userState.attributes
    },
    getSex(): string {
      return this.userState.attributes?.sex
    },
    getAvatar(): number {
      return this.userState.attributes?.avatar
    },
    getAvatarBg(): number {
      return this.userState.attributes?.avatar_background
    },
    getAvatarRace(): string {
      return this.userState.attributes?.avatar_race
    },
    getAvatarHair(): string {
      return this.userState.attributes?.avatar_hair
    },
    getAvatarHairColor(): string {
      return this.userState.attributes?.avatar_hair_colour
    },
    getLanguage(): string {
      return this.userState.attributes?.language
    },
    getCountry(): string {
      return this.userState.user_data?.country?.three_letter_code
    },
    getCountryPayment(): string {
      return this.userState.attributes.country_payment
    },
    getUserData(): UserData {
      return this.userState.user_data ?? null
    },
    getStats(): Stats {
      return this.userState.user_data.stats
    },
    getClub(): ClubStats {
      return this.userState.user_data?.club_stats
    },
    getLevel(): number {
      return this.userState?.user_data?.stats?.LEVEL ?? 0
    },
    getRankingPoints(): number {
      return this.userState?.user_data?.stats?.RANKING_POINTS ?? 0
    },
    getNotShowConfirm(): boolean {
      return this.notShowConfirm
    },
    getAutoRepair(): boolean {
      return this.userState.attributes.auto_repair
    },
    getAutoBestEquip(): boolean {
      return this.userState.attributes.auto_best_equip
    },
    getSoundVolume(): boolean {
      return !!+this.isSoundEnabled
    },
    getMusicVolume(): boolean {
      return !!+this.isMusicEnabled
    },
    getEventBadges(): EventBadgeInterface[] {
      return this.userState?.event_badges
    },
    getClubRequestNotifications(): boolean {
      return this.userState.attributes.club_requests_notifications ?? true
    },
    getHasToken(): boolean {
      return this.hasToken
    },
    getShowResultScreen(): boolean {
      return this.userState.attributes?.show_result_screen
    },
    showPushNotifications(): boolean {
      return this.userState.attributes?.push_notifications
    },
    showBuildingsTitles(): boolean {
      return this.userState.attributes?.buildings_names
    },
    showTransactionsAlert(): boolean {
      return this.userState.attributes?.gems_transactions_warnings
    },
    getUserAvatars(): UpdatedAvatar[] {
      return this.avatars?.filter((avatar: UpdatedAvatar): boolean => avatar.number !== 0)
    },
    getUserBackgrounds(): UpdatedBackgrounds[] {
      return this.backgrounds
    },
    getUserAvatarData(): UpdatedAvatar {
      if (!this.avatars) return
      return this.avatars
        .filter((avatar: UpdatedAvatar): boolean => avatar.number !== 0)
        .find(
          (avatar: UpdatedAvatar): boolean => avatar.number === this.userState.attributes?.avatar,
        )
    },
    getAvailablePasses(): Passes[] {
      return this.userState.user_data?.passes ?? []
    },
    getRookiePassInfo(): Passes {
      return (
        this.userState.user_data?.passes?.find(
          (pass: Passes): boolean => pass.store_id === ROOKIE_PASS,
        ) ?? {
          state: '',
          store_id: '',
          remaining_datetime: '',
        }
      )
    },
    getEventPassInfo(): Passes {
      return (
        this.userState.user_data?.passes?.find((pass: Passes): boolean =>
          pass.store_id.includes(EVENT_PASS),
        ) ?? {
          state: '',
          store_id: '',
          remaining_datetime: '',
        }
      )
    },
    getActivePassRewards(): Reward[] {
      return (
        this.userState.user_data?.passes?.find(
          (pass: Passes): boolean => pass.state === OFFER_STATES.active,
        )?.rewards ?? []
      )
    },
    getActiveMultiplicator:
      (state) =>
      (building: string): MultiplicatorItem => {
        return (
          state.userState.multipliers[building]?.find(
            (multiplicator: MultiplicatorItem): boolean => multiplicator.active === true,
          ) ?? {
            name: MULTIPLICATOR_OFF_STATE,
            isEnabled: false,
            value: 1,
          }
        )
      },
    getMultiplicators(): Multiplicator {
      return this.userState.multipliers ?? null
    },
    isAdmin(): boolean {
      return [SUPER_ADMIN, ADMIN].includes(this.userState.user_data?.role)
    },
    showAdminSkipButtons(): boolean {
      return (
        this.allowedENVs.includes(import.meta.env.VITE_ENV) &&
        [SUPER_ADMIN, ADMIN].includes(this.userState.user_data?.role)
      )
    },
    showTrainingLimitConfirm(): boolean {
      return this.userState.attributes?.training_hall_attribute_limit_warning
    },
    // Clubs getters
    getClubLogo(): number {
      return Number(this.userState.user_data?.club_stats?.logo_id)
    },
    getClubLogoBg(): number {
      return Number(this.userState.user_data?.club_stats?.logo_background_id)
    },
    getHasClub(): boolean {
      return !!(this.userState?.user_data?.club_stats ?? false)
    },
    getPlayerClubId(): string {
      return this.userState?.user_data?.club_stats?.id
    },
    getClubName(): string {
      return this.userState.user_data?.club_stats?.name
    },
    isClubInChampionship(): boolean {
      return this.userState.user_data?.club_stats?.isInClubChampionship ?? false
    },
    getClubMaxMembers(): number {
      return this.userState.user_data.club_stats.members_max_length
    },
    getClubPrestige(): number {
      return this.userState.user_data.club_stats.prestige
    },
    isClubManager(): boolean {
      return this.userState.user_data?.club_stats?.role === 'manager'
    },
    isClubManagerOrAssistant(): boolean {
      return (
        this.userState.user_data?.club_stats?.role === 'manager' ||
        this.userState.user_data?.club_stats?.role === 'assistant'
      )
    },
    getGpLeavingPopup(): boolean {
      return this.userState.attributes?.grand_prize_leaving_popup
    },
    hasMaxLevel(): boolean {
      return this.userState?.user_data?.stats?.MAX_LEVEL_REACHED ?? false
    },
    getTimeToAccountDeletion(): number {
      return this.userState?.user_data?.delete_account_countdown ?? null
    },
    getFirstDisciplineId(): number {
      return this.userState.user_data?.first_discipline ?? 0
    },
    isGroupB(): boolean {
      return this.userState?.group === '21387'
    },
    isGroupCD(): boolean {
      return this.userState?.group === '21389' || this.userState?.group === '21391'
    },
    isEventShipArrived(): boolean {
      // toto sa automaticky maze po skonceni eventu
      return this.userState.attributes?.event_ship_arrived
    },
    isEventTeaserSeen(): boolean {
      // toto sa automaticky maze po skonceni eventu
      return this.userState.attributes?.event_teaser_seen ?? true
    },
    isBoardGamesEventTeaserSeen(): boolean {
      // toto sa automaticky maze po skonceni teasru a po skonceni
      return this.userState.attributes?.board_games_teaser_seen ?? false
    },
    isBoardGamesEventSeen(): boolean {
      // toto sa automaticky maze kazdy den eventu a po skonceni eventu
      return this.userState.attributes?.board_games_seen ?? false
    },
    isGamePassSeen(): boolean {
      return this.userState.attributes?.game_pass_seen ?? false
    },
    isPiggyBankSeen(): boolean {
      return this.userState.attributes?.piggy_bank_seen ?? false
    },
    isGdprPageShowed(): boolean {
      return this.gdprPageShowed
    },
    getGdprCurrentVersion(): string {
      return this.gdprCurrentVersion
    },
    getSuperMultipliers(): Multiplicator {
      return this.userState.multipliers
    },
  },
  actions: {
    async setProfileAttributes(attribute: {
      name: string
      value: string | boolean | number
    }): Promise<void> {
      const response = await internalAxios.post<{}, UserProfileEndpointInterface>(
        userProfileEndpoint,
        {
          profile_attributes: [attribute],
        },
      )
      this.userState.attributes = convertNameValue(response.attributes)
    },
    async loadUserData(): Promise<void> {
      const response = await ApiService.post<UserProfileEndpointInterface>(
        userProfileUserEndpoint,
        null,
        { force: true },
      )

      const user = { ...response[0] }
      if (!user) return
      user.attributes = convertNameValue(user.attributes)
      this.userState = user
    },
    async setUpdatedProfile(newValues: NewValues): Promise<void> {
      this.userState.user_data.username = newValues.user_data.username
      this.userState.user_data.country = newValues.user_data.country
      this.userState.attributes = convertNameValue(newValues.attributes)
    },
    async loadAvatars(): Promise<void> {
      const avatarsData = await internalAxios.get<{}, UserAvatarInterface>(userProfileAvatars)
      const avatars = avatarsData.avatars.map((avatar: Avatar): UpdatedAvatar => {
        return {
          name: translate(`avatarsAndBg.avatar_${avatar.name}`),
          number: Number(avatar.name),
          noBg: avatar.locked_bg,
        }
      })
      this.avatars = avatars
    },
    async loadBackgrounds(): Promise<void> {
      const bgData = await internalAxios.get<{}, UserAvatarInterface>(userProfileAvatars)
      const bgs = bgData.backgrounds.map((bg: Background): UpdatedBackgrounds => {
        return {
          name: translate(`avatarsAndBg.bg_${bg.name}`),
          number: Number(bg.name),
        }
      })
      this.backgrounds = bgs
    },
    async loadSystemConfig(): Promise<void> {
      const isMobile = isMobilePlugin()
      try {
        const response = await ApiService.get<UserSystemConfigInterface>(userSystemConfigEndpoint)
        const systemConfig = response.system_config
        if (!systemConfig) return
        systemConfig.forEach((setting: SystemConfig): void => {
          if (setting.name === SystemConfigNames.Sounds) this.isSoundEnabled = setting.value
          if (setting.name === SystemConfigNames.Music) this.isMusicEnabled = setting.value
        })
        sfxSound.volume(this.isSoundEnabled)
        if (!isMobile) {
          if (this.isMusicEnabled && !musicTheme.playing()) {
            musicTheme.play()
          } else {
            musicTheme.stop()
          }
        } else {
          sendToFlutter(
            '{\r\n  "event": "setMusic",\r\n "enabled": ' + (this.isMusicEnabled === 1) + ' \r\n}',
          )
        }
      } catch (error: unknown) {
        return Promise.reject(error)
      }
    },
    async setSystemConfig(config: SystemConfig[]): Promise<void> {
      config.forEach((setting: SystemConfig): void => {
        if (setting.name === SystemConfigNames.Sounds) this.isSoundEnabled = setting.value
        if (setting.name === SystemConfigNames.Music) this.isMusicEnabled = setting.value
      })
      try {
        await internalAxios.put<{}, NameValueString[]>(userSystemConfigEndpoint, {
          system_config: config,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    setNotShowConfirm(): void {
      this.notShowConfirm = !this.notShowConfirm
    },
    setHasToken(value: boolean): void {
      this.hasToken = value
    },
    setUserDataExperience(value: number): void {
      this.userState.user_data.stats.EXPERIENCE = value
    },
    setUserDataTargetExperience(value: number): void {
      this.userState.user_data.stats.TARGET_EXPERIENCE = value
    },
    setUserDataStartingExperience(value: number): void {
      this.userState.user_data.stats.STARTING_EXPERIENCE = value
    },
    setUserDataLevel(value: number): void {
      this.userState.user_data.stats.LEVEL = value
    },
    setShowAccountConnectedPopup(value: boolean): void {
      this.showAccountConnectedPopup = value
    },
    setUserData(value: UserData): void {
      this.userState.user_data = value
    },
    setClubMaxMembers(value: number): void {
      this.userState.user_data.club_stats.members_max_length = value
    },
    setClubPrestige(value: number): void {
      this.userState.user_data.club_stats.prestige = value
    },
    async fastForwardExperience(): Promise<void> {
      if (!this.showAdminSkipButtons) return

      const response = await internalAxios.post<{}, UserLevelUpInterface>(
        userFastForwardExperienceEndpoint,
      )
      this.setUserDataExperience(response.stat.value)
    },
    async fastForwardCareerQuest(questId: number): Promise<void> {
      const id = questId?.toString()
      if (!this.showAdminSkipButtons || !id) return

      try {
        await internalAxios.post<{}, string>(careerQuestFastForwardEndpoint, {
          quest_id: id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async levelUp(): Promise<void> {
      if (!this.showAdminSkipButtons) return

      const response = await internalAxios.post<{}, UserLevelUpInterface>(userLevelUpEndpoint)
      this.setUserDataExperience(response.stat.value)
    },
    setMultiplicatorState(newMultiplicatorState: {
      building: string
      name: string
    }): Promise<void> {
      this.userState.multipliers[newMultiplicatorState.building].forEach(
        (multiplicator: MultiplicatorItem): void => {
          multiplicator.active = false
        },
      )
      const multiplicator = this.userState.multipliers[newMultiplicatorState.building]?.find(
        (multiplicator: MultiplicatorItem): boolean =>
          multiplicator.name === newMultiplicatorState.name,
      )
      if (!multiplicator) return

      multiplicator.active = true
    },
    setGdprPageShowed(newValue: boolean): void {
      this.gdprPageShowed = newValue
    },
    async isRequestConsentNeeded(): Promise<boolean> {
      try {
        const response = await ApiService.get<ConsentApiResponse[]>(userConsentEndpoint)
        const gdprConsent: ConsentApiResponse | undefined = response.find(
          (item: ConsentApiResponse) => item.consent.name === ConsentType.GDPR,
        )
        if (!gdprConsent?.request_consent) return false

        this.gdprCurrentVersion = gdprConsent.current_version

        return true
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
