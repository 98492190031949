import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'
import { pathImages } from '@/globalVariables'

export const clubsBuildingsAtlasLoadData: () => AtlasDataInterface[] = () => {
  return [
    {
      name: 'clubsBuildings',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubBuildings/wsm-club-buildings-etc2rgba_v2.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubBuildings/wsm-club-buildings-astc12_v2.json',
        },
        img: {
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubBuildings/wsm-club-buildings_v2.json',
        },
      },
    },
    {
      name: 'clubsBuildingsOutlines',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubOutlines/wsm-club-buildings-outlines-etc2rgba_v2.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubOutlines/wsm-club-buildings-outlines-astc12_v2.json',
        },
        img: {
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubOutlines/wsm-club-buildings-outlines_v2.json',
        },
      },
    },
    {
      name: 'clubsLockedBuildings',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubLocked/wsm-club-buildings-locked-etc2rgba_v2.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubLocked/wsm-club-buildings-locked-astc12_v2.json',
        },
        img: {
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/clubLocked/wsm-club-buildings-locked_v2.json',
        },
      },
    },
  ]
}
