import type { AnimatedObjectWithPathsInterface } from '@/map-phaser-new/interfaces'
import { FRAMES_ANIMATION_TYPES } from '@/map-phaser-new/constants'
import {
  ballonAnimations,
  helicopterLeftToRightAnimations,
} from './animations'
import {
  helicopterLeftToRightCheckpoints,
} from './checkPointsData'
import {
  ballonTopLeftPath,
  ballonTopRightPath,
  helicopterLeftToRightPath,
} from './pathsData'

export const animatedObjectsWithPathsConfig: AnimatedObjectWithPathsInterface[] = [
   {
     animationName: 'ballonTopLeft',
     textureKey: 'animations',
     pathsData: ballonTopLeftPath,
     animationsData: ballonAnimations,
     variations: ['v01', 'v02'],
     framePrefix: 'WSM_balloon_',
     framePostfix: '_24f_',
     startFrameData: {
       startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
       startFrameKey: '03',
     },
     playAnimationWithoutCheckpoints: {
       animationKeyPostfix: 'balloonRotate',
     },
     turnOnRepeatListener: true,
     turnOnUpdateListener: false,
     turnOnYoyoListener: false,
     yoyoCalculateNewVariation: false,
     repeatCalculateNewVariation: true,
     yoyo: true,
     tweenBuilderConfig: {
       duration: 130000,
     },
     followersDepth: 11,
     startFollow: {
       repeat: -1,
       ease: 'linear',
       positionOnPath: true,
     },
     animatedInstances: [
       {
         name: 'ballonTopLeft1',
         startDelay: 0,
         yoyoDelay: 10000,
       },
     ],
   },
   {
     animationName: 'ballonTopRight',
     textureKey: 'animations',
     pathsData: ballonTopRightPath,
     variations: ['v01'],
     framePrefix: 'WSM_balloon_',
     framePostfix: '_24f_',
     startFrameData: {
       startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
       startFrameKey: '03',
     },
     playAnimationWithoutCheckpoints: {
       animationKeyPostfix: 'balloonRotate',
     },
     turnOnRepeatListener: false,
     turnOnUpdateListener: false,
     turnOnYoyoListener: false,
     yoyoCalculateNewVariation: false,
     repeatCalculateNewVariation: false,
     yoyo: true,
     tweenBuilderConfig: {
       duration: 130000,
     },
     followersDepth: 11,
     startFollow: {
       repeat: -1,
       ease: 'linear',
       positionOnPath: true,
     },
     animatedInstances: [
       {
         name: 'ballonTopRight1',
         startDelay: 0,
         yoyoDelay: 30000,
       },
     ],
   },
     {
       animationName: 'helicopterLeftToRight',
       textureKey: 'animations',
       pathsData: helicopterLeftToRightPath,
       checkpointsData: helicopterLeftToRightCheckpoints,
       animationsData: helicopterLeftToRightAnimations,
       variations: ['v01'],
       framePrefix: 'WSM_helicopter_',
       framePostfix: '_24f_',
       startFrameData: {
         startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
         startFrameKey: '14',
       },
       turnOnRepeatListener: true,
       turnOnUpdateListener: true,
       turnOnYoyoListener: false,
       yoyoCalculateNewVariation: false,
       repeatCalculateNewVariation: false,
       yoyo: false,
       tweenBuilderConfig: {
         duration: 50000,
         from: 0,
         to: 1,
       },
       followersDepth: 11,
       startFollow: {
         repeat: -1,
         ease: 'linear',
         positionOnPath: true,
       },
       animatedInstances: [
         {
           name: 'helicopterLeftToRight1',
           startDelay: 0,
         },
       ],
     },
]
