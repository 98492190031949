import { BUILDING_LOCKED_TOOLTIP_POSITION } from '@/map-phaser-new/constants'
import type { BuildingConfigInterface } from '@/map-phaser-new/interfaces'

export const trainingHallConfig: BuildingConfigInterface = {
  textureKey: 'buildings',
  frameKey: 'training_hall',
  frameLevelDivider: 4,
  routeConfig: {
    routeName: 'TrainingDisciplines',
    soundName: 'click-map-artefact',
    isRouteParamIdNeeded: false,
    openBuildingEndpoint: 'meta/buildings/open/training_hall',
  },
  positionX: 1522,
  positionY: 1137,
  depth: 4,
  originX: 0,
  keyForTranslateTitle: 'map.trainingHallName',
  buildingTitleConfig: {
    positionX: 1725,
    positionY: 1360,
    depth: 7,
    textConfig: {
      originX: 0.5,
      positionX: -40,
      fontName: 'Nunito Sans',
      fontSize: '24px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontColor: '#fff',
    },
    backgroundImageConfig: {
      textureName: 'common',
      frameNameRightSide: 'right_side',
      frameNameRightSideLocked: 'right_side_locked',
      frameNameLeftSide: 'left_side',
      frameNameLeftSideLocked: 'left_side_locked',
      originX: 0.5,
      widthCorrection: 100,
      heightCorrection: 10,
    },
  },
  outlineConfig: {
    framePostFix: '_outline',
    depth: 6,
    originX: 0,
    positionByLevel: {
      '1': { positionX: 1522, positionY: 1137 },
      '2': { positionX: 1522, positionY: 1137 },
      '3': { positionX: 1522, positionY: 1137 },
      '4': { positionX: 1522, positionY: 1137 },
      '5': { positionX: 1522, positionY: 1137 },
    },
    textureKey: 'buildingsOutlines',
  },
  inputConfig: {
    useHandCursor: true,
  },
  buildingLockedConfig: {
    textureKey: 'lockedBuildings',
    framePostfix: '_locked',
    lockImageTextureKey: 'lock',
    positionX: 1715,
    positionY: 1240,
    lockImageDepth: 6,
    depth: 5,
    tooltipConfig: {
      tooltipPosition: BUILDING_LOCKED_TOOLTIP_POSITION.Top,
      tooltipTextureHeight: '213px',
      tooltipTextureWidth: '444px',
      depth: 10,
      titleConfig: {
        originX: 0.5,
        fontSize: '30px',
        fontName: 'Nunito Sans',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontColor: '#fff',
      },
      subtitleConfig: {
        originX: 0.5,
        originY: 0.5,
        fontName: 'Nunito Sans',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontColor: '#fff',
        tooltipSubtitleTranslateKey: 'common.comingSoon',
      },
      tooltipHideDelay: 2000,
      tooltipLeftTextureName: 'building_tooltip_left',
      tooltipRightTextureName: 'building_tooltip_right',
      tooltipTopTextureName: 'building_tooltip_top',
    },
  },
  positionIndicatorConfig: {
    xCorrectionValue: 60,
    yCorrectionValue: 80,
  },
  tutorialSubBuilding: 'arena',
}
