<template>
  <div class="app-input-wrapper relative" :class="error ? 'has-error' : ''">
    <input
      :id="id"
      v-model="model"
      v-tippy="{
        allowHTML: true,
        content: error && !noTooltip ? error : '',
        placement: tooltipPlacement,
        theme: 'RED',
      }"
      :type="inputType"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxLength"
      class="app-input"
      :class="[theme, error ? 'has-error' : '', isOk ? 'is-ok' : '']"
      autocomplete="off"
      @input="emitInput($event)"
      @blur="$emit('blur')"
      @keydown.enter.prevent="sendingByEnter ? enterPress($event) : blurInput($event)"
    />
    <div
      v-if="error || isOk"
      class="app-input-color-corner absolute"
      :class="error ? 'error' : ''"
    />
    <div
      v-if="type === 'password'"
      class="app-input-pwd-icon"
      :class="{ visible: pwdVisible }"
      @click="togglePwdVisible()"
    >
      <div class="icon-eye absolute" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface ComponentData {
  pwdVisible: boolean
  inputType: string
}

export default defineComponent({
  name: 'AppInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'text-input',
    },
    id: {
      type: String,
      default: 'id-input',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator(value: string): boolean {
        return ['text', 'password'].includes(value)
      },
    },
    error: {
      type: [String, Boolean],
      default: '', // string will be used as error message tooltip, if prop noTooltip is not true
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 42,
    },
    tooltipPlacement: {
      type: String,
      default: 'left',
      validator(value: string): boolean {
        return ['left', 'right', 'top', 'bottom'].includes(value)
      },
    },
    theme: {
      type: String,
      default: 'theme-1',
      validator(value: string): boolean {
        return ['theme-1', 'theme-2'].includes(value)
      },
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
    sendingByEnter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input', 'blur', 'inputWatch', 'enter'],
  data(): ComponentData {
    return {
      pwdVisible: false,
      inputType: 'text',
    }
  },
  computed: {
    model: {
      get(): string {
        return this.value
      },
      set(value: string): void {
        this.$emit('input', value)
      },
    },
  },

  mounted(): void {
    this.inputType = this.type
  },
  methods: {
    togglePwdVisible(): void {
      this.pwdVisible = !this.pwdVisible
      this.inputType = this.pwdVisible ? 'text' : 'password'
    },

    emitInput(e: Event) {
      this.$emit('inputWatch', (e.target as HTMLInputElement).value)
    },
    enterPress(e: Event) {
      this.$emit('enter', e)
      this.blurInput(e)
    },
    blurInput(e: Event): void {
      const target = e.target as HTMLElement
      target.blur()
    },
  },
})
</script>

<style lang="scss">
.app-input {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 1.25rem 1.875rem;
  @if $isWsm {
    font-style: italic;
  }
  font-size: theme('fontSize.40');

  &:focus {
    outline: none;
  }

  &.theme-1 {
    color: theme('colors.texts.standard.default');
    @if $isWsm {
      background: #0a1b2f;
      border: 0.063rem solid #5ba3dc;
    }
    @if $isSsm {
      background: #1d1f2c;
      border: 0.063rem solid #456786;
    }
    &::placeholder {
      color: theme('colors.texts.standard.notImportant');
      opacity: 50%;
      @if $isWsm {
        font-style: italic;
      }
    }
  }

  &.theme-2 {
    color: theme('colors.texts.standard.default');
    @if $isWsm {
      background: #194662;
      border: 0.063rem solid #5ba3dc;
    }
    @if $isSsm {
      border: 0.125rem solid #4c648f;
      background-color: #1d1f2c;
    }

    &::placeholder {
      color: theme('colors.texts.standard.additional');
      opacity: 50%;
    }
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active,
  &:-webkit-autofill::first-line,
  &:-internal-autofill-selected {
    font-size: theme('fontSize.40');
    @if $isWsm {
      font-style: italic;
    }
    color: theme('colors.texts.standard.additional') !important;
    -webkit-text-fill-color: theme('colors.texts.standard.additional');
    background: #0a1b2f !important;
    box-shadow: 0 0 0 3rem #0a1b2f inset;
    -webkit-box-shadow: 0 0 0 3rem #0a1b2f inset;

    @if $isWsm {
      font-family: theme('fontFamily.roboto');
    }

    @if $isSsm {
      font-family: theme('fontFamily.nunitoSans');
    }
  }

  &.has-error {
    border: 2px solid theme('colors.texts.standard.danger');
  }

  &.is-ok {
    border: 2px solid theme('colors.texts.standard.positive');
  }

  &-color-corner {
    width: 0;
    height: 0;
    top: 0;
    right: 0;
    border: 1.25rem solid transparent;
    border-bottom: 1.25rem solid theme('colors.texts.standard.positive');
    transform: rotate(45deg) translate(0rem, -1.65rem);

    &.error {
      border-bottom: 1.25rem solid theme('colors.texts.standard.danger');
    }
  }

  &-wrapper {
    width: 25rem;
    height: 4.375rem;

    &:not(.has-error):before {
      @if $isWsm {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 1.563rem;
        height: 1.563rem;
        background: transparent;
        border-bottom: 0.094rem solid #b5deff;
        border-left: 0.094rem solid #b5deff;
        z-index: 1;
      }
    }

    &:not(.has-error):after {
      @if $isWsm {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1.563rem;
        height: 1.563rem;
        background: transparent;
        border-top: 0.094rem solid #b5deff;
        border-right: 0.094rem solid #b5deff;
      }
    }
  }

  &-pwd-icon {
    width: 4rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0.5rem;
    color: rgba(50, 97, 148, 0.96);
    font-style: normal;
    font-size: 3rem;
    cursor: pointer;

    & .icon-eye {
      width: 2.3rem;
      height: 1.5rem;
      background: url($path-icons + 'icon-eye.avif') no-repeat center center;
      background-size: 2.3rem 1.5rem;
      top: 1.5rem;
      right: 0.75rem;
    }

    &:not(.visible)::after {
      content: '/';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      text-align: center;
    }
  }
}
</style>
