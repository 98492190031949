<template>
  <footer class="flexing" :class="{ upgrade: isPossibleUpgrade && !footerData.premium }">
    <section v-if="footerData.premium && footerData.expired">
      <span class="text-40 flexing uppercase ml-12">
        <p class="text-texts-standard-danger font-bold mx-6">
          {{ $t('equipmentShop.expired') }}
        </p>
      </span>
    </section>
    <tippy v-else-if="footerData.premium" theme="WSM" placement="top">
      <app-timer
        v-if="Number(footerData.premium?.time) > 0"
        :time="Number(footerData.premium.time)"
        :italic="false"
        add-class="ml-12"
        :no-countdown="footerData.premium?.locked"
        @countdown-action="$emit('reloadData')"
      />
      <template #content>
        <p>
          {{
            $t(footerData.premium?.locked ? 'equipmentShop.offerExpires' : 'equipmentShop.timeLeft')
          }}
        </p>
      </template>
    </tippy>
    <section v-else-if="isUnlocked">
      <span class="text-36 zone-buy flexing uppercase">
        [
        <p class="text-texts-standard-important font-bold mx-6">
          {{ $t('button.purchase') }}
        </p>
        ]
      </span>
    </section>
    <section v-else class="flexing stars-container">
      <div
        v-for="(star, i) in footerData.stars"
        :key="i"
        :class="['icon-star-' + getStarType(star.type) + '-56', { 'stars-opacity': isInAction }]"
      />
    </section>
    <equipment-upgrade-icon
      v-if="isPossibleUpgrade && !footerData.premium"
      :custom-position="{
        right: '-0.25rem',
      }"
      icon-size="sm"
    />
  </footer>
</template>

<script lang="ts">
import EquipmentUpgradeIcon from '@/components/Equipment/EquipmentUpgradeIcon.vue'
import { EQUIPMENT_FUNCTIONAL, EQUIPMENT_PURCHASED, EQUIPMENT_UNLOCKED } from '@/globalVariables'
import type { FooterData } from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

interface ComponentData {
  EQUIPMENT_UNLOCKED: typeof EQUIPMENT_UNLOCKED
}

export default defineComponent({
  name: 'EquipmentItemBoxFooter',
  components: {
    EquipmentUpgradeIcon,
  },
  props: {
    footerData: {
      type: Object as PropType<Nullable<FooterData>>,
      default: () => null,
    },
  },
  emits: ['reloadData'],
  data(): ComponentData {
    return {
      EQUIPMENT_UNLOCKED,
    }
  },
  computed: {
    isInAction(): boolean {
      return !!(this.isRepairing || this.isDelivering || this.isUpgrade)
    },
    isUnlocked(): boolean {
      return !!this.footerData.unlocked
    },
    isDelivering(): boolean {
      return !!this.footerData.delivering
    },
    isRepairing(): boolean {
      return !!this.footerData.repair
    },
    isUpgrade(): boolean {
      return !!this.footerData.upgrade
    },
    // kontrola ci je necinny stav
    isFunctional(): boolean {
      return this.footerData.durabilityState === EQUIPMENT_FUNCTIONAL && !this.isUpgrade
    },
    // kontrola zobrazovania info (zelena sipocka) ci je mozny upgrade
    isPossibleUpgrade(): boolean {
      return (
        this.footerData.stars?.find(
          (star: FooterData['stars'][0]): boolean => star.type === 'unlocked',
        ) && this.isFunctional
      )
    },
  },
  methods: {
    getStarType(type: FooterData['stars'][0]['type']): string {
      switch (type) {
        case EQUIPMENT_PURCHASED:
          return 'full'
        case EQUIPMENT_UNLOCKED:
          return 'empty'
        default:
          return 'lock'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

footer {
  position: relative;
  flex: 1;

  .zone-buy {
    color: #5199c6;
  }

  .stars-opacity {
    opacity: 60%;
  }

  &.upgrade {
    .stars-container {
      margin-right: 0.75rem;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url($path-equipment + 'box-backgrounds/new/bg-green-upgrade.avif');
    }
  }
  .stars-container {
    :deep(.icon-star-full-56) {
      margin: 0 0.2rem;
    }
  }
}
</style>
