<template>
  <section class="detail-view w-full min-h-0 flex flex-col flex-grow safe-area">
    <main v-if="!isLoading" class="w-full h-full flex flex-col">
      <div
        class="detail-main w-full min-h-0 flex flex-row flex-grow justify-center items-center px-8 pt-6 pb-4 relative"
      >
        <div class="w-full h-full flexing space-x-8">
          <div
            class="left-side h-full flex flex-col justify-start items-center"
            :class="{
              'is-finished': isFinished,
            }"
          >
            <div
              class="header w-full flex flex-col justify-start items-cente"
              :class="{ relative: isTournamentDiscipline2 }"
            >
              <div
                v-if="isTournamentDiscipline2"
                class="sponsored-badge flexing text-texts-standard-default text-28 uppercase pt-1 pr-1.5 absolute top-6 left-0"
              >
                Sponsored
              </div>
              <div class="flexing mt-10 mb-6">
                <div v-if="isTournamentDiscipline2" class="logo-discipline-2" />
                <app-discipline-icon
                  v-else
                  :discipline-id="tournament.discipline_id"
                  :size="120"
                  theme="light"
                />
              </div>
              <p
                class="tournament-name w-full flexing text-36 text-texts-standard-important font-bold uppercase"
              >
                <app-discipline-icon
                  v-if="isTournamentDiscipline2"
                  class="tournament-name-icon w-8 h-8 !border-transparent mr-2"
                  :discipline-id="tournament.discipline_id"
                  :size="32"
                  :is-transparent-bg="true"
                />
                {{ $translateDiscipline(tournament.discipline_id) }}
              </p>
              <p class="text-30 text-texts-standard-default uppercase truncate">
                {{ tournament.name }}
              </p>
            </div>
            <div
              class="attribute w-full flex flex-col justify-center items-center pt-2 -mt-8 mb-6 relative"
              :class="{ 'opacity-50': isFinished }"
            >
              <p class="text-30 text-texts-standard-important font-medium">
                {{ $t('tournaments.attribute') }}
              </p>
              <p class="flexing text-48 text-texts-standard-default font-bold">
                {{ tournament.attribute }}
                <app-discipline-icon
                  class="attribute-icon !border-transparent ml-2 relative -mt-2"
                  :discipline-id="tournament.discipline_id"
                  :size="70"
                  :is-transparent-bg="true"
                />
                <tippy
                  theme="WSM"
                  placement="top"
                  max-width="35rem"
                  class="absolute bottom-4 right-4"
                >
                  <app-icon icon-name="info-50" />
                  <template #content>
                    <div class="tooltip-content text-texts-standard-default text-30">
                      <p>
                        {{
                          !tournament.benefit
                            ? $t('tournaments.attributesInfo1')
                            : $t('tournaments.attributesInfo2')
                        }}
                      </p>
                    </div>
                  </template>
                </tippy>
              </p>
            </div>
            <template v-if="isFinished">
              <p class="text-30 text-texts-standard-default font-medium mt-auto">
                {{ $t('tournaments.finalRank') }}
              </p>
              <div class="w-full px-2 mb-8">
                <ranking-player
                  v-if="rankings?.player"
                  :player-data="rankings.player"
                  :ranking-type="RankingType.TournamentResults"
                  :hidden-columns="['result', 'rewards']"
                  :is-tournament-finished="isFinished"
                  :is-special-tournament="isTournamentDiscipline2"
                />
              </div>
              <p class="text-30 text-texts-standard-default font-medium uppercase">
                {{ $t('tournaments.yourRewards') }}
              </p>
              <div class="rewards w-full flexing pt-4 pb-2">
                <app-scrollbar
                  v-if="rankings?.player?.rewards?.length"
                  width="100%"
                  height="auto"
                  scroll="x"
                  class="space-x-4 px-4 pb-2"
                  :class="
                    rankings?.player?.rewards.length > 4 ? 'flex flex-row items-center' : 'flexing'
                  "
                >
                  <reward-box
                    v-for="(reward, index) in rankings.player.rewards"
                    :key="index"
                    :reward-icon-size="56"
                    :reward-icon-name="reward.type"
                    :reward-number="reward.value"
                    :reward-margin-size="0"
                    class="flex-shrink-0"
                  />
                </app-scrollbar>
                <div v-else class="text-30 text-texts-standard-default font-medium">
                  {{ $t('tournaments.didNotStart') }}
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-if="!meta.showAcceptStatute"
                class="benefit w-full flexing mb-auto"
                :class="{ 'is-disabled': !tournament.benefit }"
              >
                <p v-if="!tournament.benefit" class="w-4/5 text-30 text-texts-standard-default">
                  {{ $t('tournaments.benefitsNotAvailable') }}
                </p>
                <span v-else>
                  <use-benefit-button
                    v-for="(slot, slotId) in benefitTournamentsSlots"
                    :key="slotId"
                    btn-size="sm"
                    :slot-data="slot"
                    :slot-id="slotId.toString()"
                    :benefit-usage="requiredBenefits"
                    @click="showSelectBenefitPopup = true"
                    @reload-data="loadState"
                  />
                </span>
              </div>
              <tournament-button
                :tournament="tournament"
                :meta="meta"
                :context="TournamentButtonContext.Detail"
                :class="{ 'tournament-button--statuted': meta.showAcceptStatute }"
                @reload-data="loadState"
              />
              <p
                v-if="meta.showAcceptStatute"
                class="line-clamp-4 px-2 py-4 text-center text-26 leading-7 text-texts-standard-default font-normal"
                v-html="
                  $replaceLinkPlaceholder(
                    $t('tournaments.acceptStatute'),
                    TOURNAMENTS_DISCIPLINE_2_STATUTE_CLICK_URL,
                    'text-texts-standard-important font-bold',
                  )
                "
              />
            </template>
          </div>
          <div
            class="right-side h-full flex flex-col justify-start items-center flex-grow relative"
          >
            <div class="header w-full flex flex-row justify-between items-center mb-4">
              <p
                class="text-36 font-bold uppercase"
                :class="
                  isFinished ? 'text-texts-standard-default' : 'text-texts-standard-important'
                "
              >
                {{ title }}
              </p>
              <p
                class="flexing text-28 text-texts-standard-default"
                :class="{ 'opacity-50': isOpen || isRunningAndCanNotPlay }"
              >
                <template v-if="!isFinished">
                  {{ $t('tournaments.refresh') }}
                  <app-icon
                    icon-name="tournament-btn-refresh"
                    class="clickable-element cursor-pointer ml-4"
                    :class="{ 'pointer-events-none': isOpen || isRunningAndCanNotPlay }"
                    :disabled="isOpen"
                    @click="loadState"
                  />
                </template>
                <template v-else>
                  {{ getFormattedDateFromYMD(tournament.start_date) }}
                  <span class="text-texts-standard-important ml-2">
                    {{ getFormattedTimeFromHMS(tournament.start_date) }}
                  </span>
                </template>
              </p>
            </div>
            <div v-if="!isReloading" class="w-full min-h-0 flex flex-col">
              <ranking-table-head
                :ranking-type="RankingType.TournamentResults"
                :rewards-info-button="!isOpen && !isRunningAndCanNotPlay"
                :hidden-columns="hiddenColumns"
                :rounds="rounds"
              />
              <app-scrollbar
                class="rankings-scrollbar"
                width="100%"
                height="100%"
                scroll="y"
                slide="y"
              >
                <ranking-row-table
                  :table="RankingType.TournamentResults"
                  :rewards-info-button="!isOpen && !isRunningAndCanNotPlay"
                  :hidden-columns="hiddenColumns"
                  :rows="formattedRankings"
                  width="100%"
                  :highlight-club-members="false"
                  :is-tournament-finished="isFinished"
                  :is-special-tournament="isTournamentDiscipline2"
                />
              </app-scrollbar>
            </div>
            <component-loading v-else :is-loading="true" height="100%" />
          </div>
        </div>
      </div>
      <info-popup
        v-if="showSelectBenefitPopup"
        :popup-title="$t('benefits.buttonActiveBenefit')"
        @close="showSelectBenefitPopup = false"
      >
        <select-benefit
          :allowed-benefit-types="benefitTypes"
          :required-benefits="requiredBenefits"
          :current-slots="benefitSlots()"
          @close-select-popup="closeSelectAndRefreshSlots"
        />
      </info-popup>
    </main>
    <component-loading v-else :is-loading="true" height="100%" />
  </section>
</template>

<script lang="ts">
import {
  STARTS,
  GEMS,
  BENEFITS_TOURNAMENTS,
  gamesConfigEndpoint,
  TOURNAMENTS_CONFIG,
  TOURNAMENTS_DISCIPLINE_2_STATUTE_CLICK_URL,
} from '@/globalVariables'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import SelectBenefit from '@/components/Benefits/SelectBenefit.vue'
import UseBenefitButton from '@/components/Button/UseBenefitButton.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import RewardBox from '@/components/GlobalComponents/RewardBox.vue'
import TournamentButton, { TournamentButtonContext } from '../TournamentButton.vue'
import { RankingType, type RankingUser } from '@/interfaces/RankingsInterfaces'
import { useTournamentsDetailStore } from '@/store/pinia/tournaments/useTournamentsDetailStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { TournamentState } from '@/interfaces/Tournaments'
import type Reward from '@/interfaces/Reward'
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import type { UserBenefitsSlotsApiResponse } from '@/interfaces/Benefits'
import type { BenefitSlot, ActiveBenefitSlots } from '@/interfaces/Benefits'

interface TournamentsConfigApiResponse {
  benefit: number
  cost: number
  equipment_durability: number
  parameter: string
}

interface ComponentData {
  GEMS: typeof GEMS
  STARTS: typeof STARTS
  TournamentState: typeof TournamentState
  TournamentButtonContext: typeof TournamentButtonContext
  RankingType: typeof RankingType
  isConfirmPopupOpen: boolean
  isErrorPopupOpen: boolean
  isLoading: boolean
  isReloading: boolean
  isJoining: boolean
  showSelectBenefitPopup: boolean
  requiredBenefits: number
  TOURNAMENTS_DISCIPLINE_2_STATUTE_CLICK_URL: typeof TOURNAMENTS_DISCIPLINE_2_STATUTE_CLICK_URL
}

export default defineComponent({
  components: {
    InfoPopup,
    SelectBenefit,
    UseBenefitButton,
    RankingTableHead,
    RankingRowTable,
    RankingPlayer,
    RewardBox,
    TournamentButton,
  },
  data(): ComponentData {
    return {
      GEMS,
      STARTS,
      TournamentState,
      TournamentButtonContext,
      RankingType,
      isConfirmPopupOpen: false,
      isErrorPopupOpen: false,
      isLoading: false,
      isReloading: false,
      isJoining: false,
      showSelectBenefitPopup: false,
      requiredBenefits: 1,
      TOURNAMENTS_DISCIPLINE_2_STATUTE_CLICK_URL,
    }
  },
  computed: {
    ...mapState(useTournamentsDetailStore, ['meta', 'tournament', 'rankings', 'rewards']),
    ...mapState(useResponseTaskStore, {
      getBenefitsSlots: 'getBenefitsSlots',
    }),
    benefitTypes(): string[] {
      return this.benefitTournaments.map((benefit): string => benefit.benefit_slot_type)
    },
    benefitTournaments(): UserBenefitsSlotsApiResponse[] {
      return (
        this.getBenefitsSlots?.filter(
          (benefit: UserBenefitsSlotsApiResponse): boolean =>
            benefit.benefit_building === BENEFITS_TOURNAMENTS,
        ) ?? []
      )
    },
    benefitTournamentsSlots(): BenefitSlot['slots'] {
      return this.benefitTournaments.reduce(
        (acc: BenefitSlot['slots'], val: UserBenefitsSlotsApiResponse) => {
          acc[val?.slot_id] = val.benefit
          return acc
        },
        {},
      )
    },
    isOpen(): boolean {
      return this.tournament.tournament_state === TournamentState.Open
    },
    isRunning(): boolean {
      return this.tournament.tournament_state === TournamentState.Running
    },
    isRunningAndCanNotPlay(): boolean {
      return this.isRunning && this.tournament.user_state === null
    },
    isFinished(): boolean {
      return this.tournament.tournament_state === TournamentState.Finished
    },
    title(): string {
      if (this.isOpen) return this.$t('tournaments.tournamentNotStarted')
      if (this.isRunning) return this.$t('tournaments.tournamentInProgress')
      if (this.isFinished) return this.$t('tournaments.tournamentEnded')
      return ''
    },
    formattedRankings(): RankingUser[] {
      if (this.isOpen || this.isRunningAndCanNotPlay) {
        return Object.values(this.rewards).map(
          (rewards: Reward[]): RankingUser => ({
            id: '',
            name: '',
            LEVEL: 0,
            clubId: '',
            clubLogoId: 0,
            clubLogoBgId: 0,
            eventBadges: [],
            rewards,
            result: 0,
            discipline_id: 0,
          }),
        )
      }

      return (
        Object.keys(this.rankings).reduce((array: RankingUser[], userId: string): RankingUser[] => {
          if (userId !== 'player') {
            array.push({
              id: userId,
              name: this.rankings[userId].username,
              level: this.rankings[userId].LEVEL,
              clubId: this.rankings[userId].club_id,
              clubLogoId: this.rankings[userId].club_logo_id,
              clubLogoBgId: this.rankings[userId].club_background_logo_id,
              eventBadges: this.rankings[userId].event_badges ?? null,
              discipline_id: this.rankings[userId].discipline_id || this.tournament.discipline_id,
              ...this.rankings[userId],
            })
          }
          return array
        }, []) ?? []
      )
    },
    hiddenColumns(): string[] {
      const hidden = []
      if (this.isOpen || this.isRunningAndCanNotPlay) hidden.push('name', 'result')
      if ((this.tournament?.rounds_total ?? 0) <= 1) hidden.push('rounds')
      return hidden
    },
    rounds(): number | null {
      return this.formattedRankings?.[0]?.rounds?.length ?? null
    },
    isTournamentRouteDiscipline2(): boolean {
      return this.$route.name === this.$getWebView('TournamentsDetailDiscipline2')
    },
    isTournamentDiscipline2(): boolean {
      return this.$isWsm && this.isTournamentRouteDiscipline2
    },
  },
  watch: {
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadState()

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    this.isLoading = true
    await this.loadState()
    this.isLoading = false
  },
  methods: {
    getFormattedTimeFromHMS,
    getFormattedDateFromYMD,
    ...mapActions(useTournamentsDetailStore, {
      _loadState: 'loadState',
      _loadStateDiscipline: 'loadStateDiscipline',
    }),
    async loadState(): Promise<void> {
      this.isReloading = true

      await this.loadConfig()

      if (this.isTournamentDiscipline2) {
        await this._loadStateDiscipline(
          2,
          this.$route?.params?.id ? +this.$route.params.id : null,
          true,
        )
      } else {
        await this._loadState(+this.$route.params.id, true)
      }

      this.isReloading = false
    },
    async loadConfig(): Promise<void> {
      const tournamentsConfig = await this.$axios.post<
        {},
        TournamentsConfigApiResponse,
        { keys: string }
      >(gamesConfigEndpoint, {
        keys: TOURNAMENTS_CONFIG,
      })

      this.requiredBenefits = tournamentsConfig?.benefit ?? this.requiredBenefits
    },
    benefitSlots(): ActiveBenefitSlots {
      const slotsRaw = this.benefitTournamentsSlots

      const slots = {}
      for (const slotId in slotsRaw) {
        slots[slotId] = slotsRaw[slotId] ? slotsRaw[slotId].benefit_gdd_id : 0
      }

      return slots
    },
    async closeSelectAndRefreshSlots(): Promise<void> {
      this.showSelectBenefitPopup = false
      this.loadState()
    },
  },
})
</script>

<style lang="scss" scoped>
.detail-view {
  .detail-main {
    .left-side {
      width: 32.875rem;
      @if $isSsm {
        background-color: #202f53;
        border: solid 0.125rem #4c648f;
      }
      @if $isWsm {
        background-color: #1b2f47;
        border: solid 0.125rem #6c9ec4;
      }

      &.is-finished {
        :deep(.player-position-position) {
          flex-shrink: 0;
        }

        :deep(.player-position-player) {
          @apply truncate;
          width: auto;
          padding-right: 1rem;
          margin-right: auto;
        }

        .rewards {
          min-height: 10.5rem;

          @if $isWsm {
            background-color: #09172a;
          }

          @if $isSsm {
            background-color: #232a40;
          }
        }
      }

      .header {
        height: 21.813rem;
        @include background(url('#{$path-images}/tournaments/detail/header-bg.avif'), contain);

        .sponsored-badge {
          width: 10.375rem;
          height: 2.25rem;
          background-image: linear-gradient(to right, #2bc82f, #0c9e0c);
          clip-path: polygon(0% 0%, 100% 0%, 95% 100%, 0% 100%);
        }

        .logo-discipline-2 {
          @include background(
            url('#{$path-images}/tournaments/logos/logo-discipline-2-white.avif'),
            contain
          );
          width: 9rem;
          height: 9rem;
        }

        .tournament-name {
          height: 2.625rem;
          background-image: linear-gradient(
            to right,
            transparent,
            rgba(10, 29, 54, 0.5),
            rgba(10, 29, 54, 0.5),
            transparent
          );
        }
      }

      .attribute {
        height: 7.75rem;
        border-style: solid;
        border-width: 0.125rem;
        border-image-slice: 1;
        line-height: 1.25;
        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent 0%,
            rgba(52, 65, 93, 0.85) 20%,
            rgba(52, 65, 93, 0.85) 80%,
            transparent 100%
          );
          border-image-source: linear-gradient(
            to right,
            transparent 0%,
            rgba(88, 107, 157, 0.85) 20%,
            rgba(88, 107, 157, 0.85) 80%,
            transparent 100%
          );
        }
        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent 0%,
            rgba(51, 95, 128, 0.85) 20%,
            rgba(51, 95, 128, 0.85) 80%,
            transparent 100%
          );
          border-image-source: linear-gradient(
            to right,
            transparent 0%,
            #6c9ec4 20%,
            #6c9ec4 80%,
            transparent 100%
          );
        }

        &-icon {
          width: 4.375rem;
          height: 4.375rem;
        }
      }

      .benefit {
        height: 7.5rem;
        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent 0%,
            rgba(52, 65, 93, 0.85) 20%,
            rgba(52, 65, 93, 0.85) 80%,
            transparent 100%
          );
        }
        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent 0%,
            rgba(51, 95, 128, 0.85) 20%,
            rgba(51, 95, 128, 0.85) 80%,
            transparent 100%
          );
        }

        &.is-disabled {
          @if $isSsm {
            background-image: linear-gradient(
              to right,
              transparent,
              rgba(132, 30, 17, 0.7),
              transparent
            );
          }
          @if $isWsm {
            background-image: linear-gradient(
              to right,
              transparent,
              rgba(195, 24, 24, 0.7),
              transparent
            );
          }
        }
      }

      .time {
        width: 26.25rem;
        height: 3.75rem;

        &:not(.no-bg) {
          @if $isSsm {
            background-image: linear-gradient(
              to right,
              transparent,
              rgba(14, 231, 204, 0.5),
              transparent
            );
          }
          @if $isWsm {
            background-image: linear-gradient(
              to right,
              transparent,
              rgba(63, 228, 67, 0.5),
              transparent
            );
          }
        }

        .timer {
          width: 16.875rem;
        }
      }

      :deep() {
        .join-button,
        .enter-button {
          width: 30.625rem;
        }

        .tournament-button--statuted {
          margin-bottom: auto;
        }
      }
    }

    .right-side {
      width: 81.25rem;

      .header {
        height: 4.375rem;
      }
    }
  }

  :deep(.tournament-name-icon.\!border-transparent > .discipline-box),
  :deep(.attribute-icon.\!border-transparent > .discipline-box) {
    box-shadow: none !important;
  }

  :deep(.player-position-position.is-rank-1) {
    @if $isWsm {
      background-image: linear-gradient(to top, #ffef84, #f6c717);
    }
    @if $isSsm {
      background-image: linear-gradient(to top, #daab36, #ffdf91);
    }
  }

  :deep(.player-position-position.is-rank-2) {
    @if $isWsm {
      background-image: linear-gradient(to top, #a4a4a4, #fff);
    }
    @if $isSsm {
      background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
    }
  }

  :deep(.player-position-position.is-rank-3) {
    @if $isWsm {
      background-image: linear-gradient(to top, #e77447, #ffb99e);
    }
    @if $isSsm {
      background-image: linear-gradient(to top, #f58d57, #ffb28a);
    }
  }

  :deep() {
    .player-position {
      .player-position-position.is-rank-dash {
        color: theme('colors.texts.standard.dark');
      }
    }
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!border-transparent {
  border-color: transparent !important;
}
</style>
