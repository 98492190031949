import type { MapImageConfigInterface } from '@/map-phaser-new/interfaces'

export const mapImageConfig: MapImageConfigInterface = {
  textureKey: 'map',
  mapOverlaysConfig: [
    {
      textureKey: 'overlayimage',
      depth: 3,
    },
  ],
}
