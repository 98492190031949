import type { PiggyBankOsraConfigInterface } from '@/map-phaser-new/interfaces'

export const piggyBankOsra: PiggyBankOsraConfigInterface = {
  textureKey: 'piggy_bank_osra_image',
  positionX: 1790,
  positionY: 1010,
  notificationX: 1800,
  notificationY: 895,
  depth: 3,
  origin: 0.5,
  inputConfig: {
    useHandCursor: true,
  },
  outlineConfig: {
    textureKey: 'piggy_bank_osra_image_outline',
    depth: 3,
  },
}
