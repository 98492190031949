import type {
  BuildingsConfigInterface,
  InteractiveObjectsInterface,
  BoardGamesEventOsraConfigInterface,
  ConnectAccountOsraConfigInterface,
  RewardedItemConfigInterface,
  WeatherConfigInterface,
  SpecificObjectsInterface,
  AdditionalConfigsInterface,
  PiggyBankOsraConfigInterface,
  SpecialTournamentOsraConfigInterface,
} from '@/map-phaser-new/interfaces'
import { BaseObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import {
  BuildingsHandler,
  RewardedItemsHandler,
  BoardGamesEventOsraHandler,
  ConnectAccountOsraHandler,
  WeatherHandler,
  PiggyBankOsraHandler,
  SpecialTournamentOsraHandler,
} from './specificInteractiveObjectsHandlers'
import { useBoardGamesEventStore } from '@/store/pinia/boardGamesEventStore'

export class InteractiveObjectsHandler extends BaseObjectHandler<SpecificObjectsInterface> {
  constructor(gameFromEnv: string, activeScene: Phaser.Scene) {
    super(gameFromEnv, activeScene)
  }

  public async setUp(configData: InteractiveObjectsInterface): Promise<void> {
    // we don't have to wait for those items
    this.createBoardGamesEventOsra(
      useBoardGamesEventStore().isIceAndFireEvent
        ? configData.iceAndFireEventOsra
        : configData.boardGamesEventOsra,
      configData.additionalConfigs,
    )
    this.createPiggyBankOsra(configData.piggyBankOsra, configData.additionalConfigs)
    this.createSpecialTournamentOsra(configData.specialTournamentOsra, configData.additionalConfigs)
    this.createConnectAccountOsra(configData.connectAccountOsra, configData.additionalConfigs)
    this.createRewardedItems(configData.rewardedItems, configData.additionalConfigs)
    this.createWeather(configData.weather)

    // we just have to wait for buildings
    await this.createBuildings(configData.buildings, configData.additionalConfigs)
  }

  private async createBuildings(
    buildingsConfig: BuildingsConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    const buildingsHandler = new BuildingsHandler()
    await buildingsHandler.setUp(this.activeScene, buildingsConfig, additionalConfigs)
  }

  private async createWeather(weatherConfig: WeatherConfigInterface): Promise<void> {
    if (!weatherConfig) return

    const weatherHandler = new WeatherHandler()
    await weatherHandler.setUp(this.activeScene, weatherConfig)
  }

  private async createRewardedItems(
    rewardedItemsConfig: RewardedItemConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    if (!rewardedItemsConfig) return

    const rewardedItemsHandler = new RewardedItemsHandler()
    await rewardedItemsHandler.setUp(this.activeScene, rewardedItemsConfig, additionalConfigs)
  }

  private async createBoardGamesEventOsra(
    osraConfig: BoardGamesEventOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    if (!osraConfig || !additionalConfigs) return

    const boardGamesEventOsraHandler = new BoardGamesEventOsraHandler()
    await boardGamesEventOsraHandler.setUp(this.activeScene, osraConfig, additionalConfigs)
  }

  private async createPiggyBankOsra(
    osraConfig: PiggyBankOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    if (!osraConfig || !additionalConfigs) return

    const piggyBankOsraHandler = new PiggyBankOsraHandler()
    await piggyBankOsraHandler.setUp(this.activeScene, osraConfig, additionalConfigs)
  }

  private async createSpecialTournamentOsra(
    osraConfig: SpecialTournamentOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    if (!osraConfig || !additionalConfigs) return

    const specialTournamentOsraHandler = new SpecialTournamentOsraHandler()
    await specialTournamentOsraHandler.setUp(this.activeScene, osraConfig, additionalConfigs)
  }

  private async createConnectAccountOsra(
    osraConfig: ConnectAccountOsraConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    if (!osraConfig || !additionalConfigs) return

    const connectAccountOsraHandler = new ConnectAccountOsraHandler()
    await connectAccountOsraHandler.setUp(this.activeScene, osraConfig, additionalConfigs)
  }
}
