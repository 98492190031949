import type { TournamentsCalendarStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsCalendarStateApiResponse'
import type { TournamentsDetailStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsDetailStateApiResponse'
import type { TournamentsHistoryStateApiResponse } from '@/interfaces/responses/tournaments/TournamentsHistoryStateApiResponse'
import type { TournamentMeta, TournamentMetaRaw } from '@/interfaces/Tournaments'

type TournamentResponseRaw = TournamentMetaRaw &
  (
    | TournamentsCalendarStateApiResponse
    | TournamentsHistoryStateApiResponse
    | TournamentsDetailStateApiResponse
  )

export const extractMetaFromResponse = (response: TournamentResponseRaw): TournamentMeta => ({
  freeDailyJoinsLimit: response?.free_daily_joins_limit ?? 0,
  freeDailyJoins: response?.free_daily_joins ?? 0,
  joinGemsPrice: response?.join_gems_price ?? 0,
  joinStartsPrice: response?.join_starts_price ?? 0,
  playMulligansPrice: response?.play_mulligans_price ?? 0,
  playMulligansRound: response?.play_mulligans_round ?? 0,
  playWithMulligans: response?.play_with_mulligans ?? false,
  tournamentPoints: response?.tournament_points ?? 0,
  mulligans: response?.mulligans ?? 0,
  showAcceptStatute: response?.showAcceptStatute ?? false,
})
