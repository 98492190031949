<template>
  <section
    class="eq-leftside-panel relative bordered-lg"
    :class="[
      getBorder,
      {
        premium: equipmentData.premium,
        'eq-leftside-panel--bold-border': getProcessStyle !== '' && equipmentInProgress,
        [`eq-leftside-panel--bold-border-${getProcessStyle}`]: equipmentInProgress,
        'eq-leftside-panel--mobile': $isMobile(),
      },
    ]"
  >
    <div
      v-show="equipmentInProgress"
      class="flexing w-full h-full absolute eq-leftside-panel-process"
    >
      <div class="eq-leftside-panel-process-msg flexing" :class="getProcessStyle">
        <p class="uppercase flexing text-40 font-bold px-4" :class="getProcessColor">
          {{ setProcessText }}
        </p>
      </div>
    </div>
    <header
      class="eq-leftside-panel-header flexing flex-col"
      :class="{ premium: equipmentData.premium }"
    >
      <template v-if="equipmentData.isEventEquip && equipmentData.prolong?.price">
        <p class="text-texts-standard-default text-36">
          {{ $t('equipmentShop.bonusesBasedOn') }}
        </p>
        <div class="circle-stack">
          <template v-for="(item, i) in equipmentData.bonusData" :key="i">
            <attribute-circle-tooltip
              :item="item"
              :is-last-item="i === equipmentData.bonusData.length - 1"
            />
          </template>
        </div>
      </template>
      <template v-else>
        <p class="text-texts-standard-default text-36" v-html="getLevelInfo" />

        <section v-if="equipmentData.premium" class="flexing stars-container">
          <div v-for="i in 3" :key="i" class="icon-star-full-56" />
        </section>
        <app-stars-icon v-else :stars-data="stars" class="stars-icon__wrapper" :stars-size="80" />
      </template>
    </header>
    <section class="eq-leftside-panel-section relative">
      <equipment-item-attributes
        :attributes-data="getDataForAttribute"
        custom-class="text-36"
        :is-detail="true"
      />
      <div
        v-if="isDamaged && !repairInProgress"
        class="eq-leftside-panel-damaged-equipment flexing"
      >
        <div class="eq-leftside-panel-damaged-equipment-msg flexing">
          <p class="text-texts-standard-danger text-40 uppercase font-bold px-4">
            {{ $t('equipmentShop.broken') }}
          </p>
        </div>
      </div>
      <section v-if="equipmentData.premium?.time" class="premium-timer relative">
        <app-timer
          :time="Number(equipmentData.premium.time) > 0 ? Number(equipmentData.premium.time) : 0"
          :italic="false"
          add-class="ml-12 mt-4"
          :no-countdown="equipmentData.premium?.locked || Number(equipmentData.premium.time) <= 0"
          @countdown-action="reloadItemPremium"
        />
        <div
          v-if="equipmentData.prolong.canProlong && !equipmentData.premium?.locked"
          class="prolong-btn icon-plus-btn"
          @click="showProlongPopup = true"
        ></div>
      </section>
      <app-button
        v-else-if="isButtonVisible"
        v-tippy="{
          theme: 'WSM',
          content: repairTooltipText,
          placement: 'top',
          textAlign: 'center',
        }"
        btn-size="md"
        btn-type="confirm"
        :btn-text="$t('equipmentShop.repair')"
        class="m-auto my-2 eq-leftside-panel-section__button repair"
        :class="{ 'lock-button': equipmentData.equipped && !canBeRepaired }"
        :disabled="!canBeRepaired || operationInProgress"
        @click="showRepair = true"
      />
    </section>
    <info-popup
      v-if="showRepair"
      :popup-title="$t('equipmentShop.equipmentRepair')"
      @close="showRepair = false"
    >
      <div class="repairs relative">
        <section class="repairs-container">
          <div class="logo">
            <div class="icon-equipment-durability-80" />
          </div>
          <div class="repairs-container-stars flexing">
            <app-stars-icon :stars-data="stars" :stars-size="48" />
          </div>
          <p class="text-texts-standard-important text-34 uppercase life">
            {{ $t('equipmentShop.durability') }}
          </p>
          <div class="repairs-container-text flexing text-texts-standard-additional text-28">
            <p class="text-texts-standard-important">
              {{ currentLevel.parameters[EQUIPMENT_DURABILITY].currentValue }}/
            </p>
            <p>{{ currentLevel.parameters[EQUIPMENT_DURABILITY].value }}</p>
            <div class="eq-arrow" />
            <p>
              {{ currentLevel.parameters[EQUIPMENT_DURABILITY].value }}/{{
                currentLevel.parameters[EQUIPMENT_DURABILITY].value
              }}
            </p>
          </div>
        </section>
        <footer class="repairs-footer">
          <div class="price text-texts-standard-default flexing text-34">
            <p>{{ $t('common.price') }}:</p>
            <p class="font-bold text-36 ml-1">
              {{ $filters.$formatNumber(currentLevel.repair.price) }}
            </p>
            <app-main-icon :icon-size="48" icon-name="money" />
          </div>
          <app-multi-button
            btn-id="repair-btn"
            btn-type="confirm"
            :btn-text="$t('equipmentShop.repair')"
            btn-size="sm"
            multi-width="26.813rem"
            :disabled="!canStartRepairing"
            class="btn-iconized"
            @click="repair"
          >
            <template #rightPart>
              <span class="icon-time mr-2" />
              <vue-countdown
                v-slot="{ days, hours, minutes, seconds }"
                :time="currentLevel.repair.time"
                :auto-start="false"
              >
                {{ formatTime(days, hours, minutes, seconds) }}
              </vue-countdown>
            </template>
          </app-multi-button>
        </footer>
      </div>
    </info-popup>
    <confirm-popup
      v-if="showProlongPopup"
      :button-data="{
        btnId: 'btn-prolong-equip',
        btnType: 'credit',
        btnSize: 'md',
        customLeftWidth: 37,
        customRightWidth: 20,
      }"
      :button-text-left="$t('equipmentShop.extend')"
      :button-text-right="equipmentData.prolong.price"
      :popup-title="$t('equipmentShop.extendDuration')"
      custom-width="60rem"
      @close="showProlongPopup = false"
      @action="prolongEquipment()"
    >
      <section class="prolong-popup flex items-center flex-col p-8 justify-around">
        <div
          class="main text-36 text-texts-standard-default"
          v-html="
            $replacePlaceholder(
              $replaceHtmlPlaceholders(
                $replacePlaceholder(
                  $t('equipmentShop.extendDurationInfo1'),
                  '{number}',
                  '{b} %s {/b}',
                ),
                'b',
                'text-texts-standard-important',
              ),
              '%s',
              equipmentData.prolong.effectDuration,
            )
          "
        ></div>
        <div class="main text-32 text-texts-standard-additional">
          {{
            $replacePlaceholder(
              $t('equipmentShop.extendDurationInfo2'),
              '{number}',
              equipmentData.prolong.availableTo,
            )
          }}
        </div>
        <div class="main text-36 text-texts-standard-danger">
          {{ $t('equipmentShop.extendDurationInfo3') }}
        </div>
      </section>
    </confirm-popup>
  </section>
</template>

<script lang="ts">
import EquipmentItemAttributes from '@/components/Equipment/EquipmentItemAttributes.vue'
import AppStarsIcon from '@/components/GlobalComponents/AppStarsIcon.vue'
import ConfirmPopup from '@/components/Popup/ConfirmPopup.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import AttributeCircleTooltip from '@/components/Premium/Offers/AttributeCircleTooltip.vue'
import {
  EQUIPMENT_DURABILITY,
  OPERATION_DELIVERY,
  OPERATION_REPAIR,
  OPERATION_UPGRADE,
  shopPremiumEquipmentProlong,
  shopAvailablePremiumItemEndPoint,
  GAME_PASS_INSTANT_EQUIPMENT_REPAIR,
} from '@/globalVariables'
import { formatTime } from '@/helpers'
import type { ItemParameters, EquipmentParameters } from '@/helpers'
import { useEquipmentStore } from '@/store/pinia/equipment/equipmentStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { BONUS_TRANSLATION_MAPPING } from './EquipmentDetail.vue'
import type {
  FormattedEquipmentData,
  FormattedLevel,
  OngoingOperation,
  FormattedAttributesParametersData,
  FormattedAttributesData,
} from '@/interfaces/Equipment'
import type { Nullable } from '@/interfaces/utils'
import type EquipmentPremiumOffersApiResponse from '@/interfaces/responses/equipment/EquipmentPremiumOffersApiResponse'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { useGamePassStore } from '@/store/pinia/gamePassStore'

const OPERATION_MAPPING = {
  [OPERATION_REPAIR]: 'equipmentShop.repairing',
  [OPERATION_UPGRADE]: 'equipmentShop.upgrading',
  [OPERATION_DELIVERY]: 'equipmentShop.delivering',
}

interface Star {
  id: number
}

interface ComponentData {
  EQUIPMENT_DURABILITY: typeof EQUIPMENT_DURABILITY
  BONUS_TRANSLATION_MAPPING: typeof BONUS_TRANSLATION_MAPPING
  showRepair: boolean
  showProlongPopup: boolean
}

export default defineComponent({
  name: 'EquipmentDetailBox',
  components: {
    InfoPopup,
    AppStarsIcon,
    EquipmentItemAttributes,
    AttributeCircleTooltip,
    ConfirmPopup,
  },
  props: {
    currentLevel: {
      type: Object as PropType<Nullable<FormattedLevel>>,
      default: () => null,
    },
    equipmentData: {
      type: Object as PropType<Nullable<FormattedEquipmentData>>,
      default: () => null,
    },
    ongoingOperation: {
      type: Object as PropType<Nullable<OngoingOperation>>,
      default: () => null,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    itemId: {
      type: Number,
      default: 1,
    },
    isOffer: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['setOperation', 'loadItemDetail'],
  data(): ComponentData {
    return {
      EQUIPMENT_DURABILITY,
      BONUS_TRANSLATION_MAPPING,
      showRepair: false,
      showProlongPopup: false,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      money: 'getMoney',
    }),
    ...mapState(useGamePassStore, ['isEffectActive']),

    getLevelInfo(): string {
      return `${this.$replacePlaceholder(
        this.$replaceHtmlPlaceholders(
          this.$replacePlaceholder(this.$t('minigame.level'), '%s', '{b} %s{/b}'),
          'b',
          'text-texts-standard-important',
        ),
        '%s',
        this.equipmentData.premium ? '3+' : this.currentLevel.level,
      )}${!this.equipmentData.premium ? '/' + this.equipmentData.levels.length : ''}`
    },
    stars(): Star[] {
      return this.equipmentData.levels.map(
        (itemLevel: FormattedEquipmentData['levels'][0]): Star => {
          if (itemLevel.level <= this.currentLevel.level) return { id: 2 }
          if (itemLevel.level <= this.equipmentData.equipmentLevelsAvailable) return { id: 1 }
          return { id: 0 }
        },
      )
    },
    isDamaged(): boolean {
      return !!this.equipmentData.damaged
    },
    isUpgrading(): boolean {
      return this.ongoingOperation && this.ongoingOperation.type === 'upgrade'
    },
    isDelivering(): boolean {
      return this.ongoingOperation && this.ongoingOperation.type === 'equipment_delivery'
    },
    getDataForAttribute(): FormattedAttributesData {
      return {
        parameters: this.formatAttributeData,
        baseValue:
          (this.currentLevel.parameters as ItemParameters).item_attribute_bonus ??
          (this.currentLevel.parameters as EquipmentParameters).equipment_attribute_bonus
            ?.baseValue,
        damaged: this.isDamaged,
        unlocked: false,
        disciplineId: this.disciplineId,
        currentDurability: this.currentLevel.parameters[EQUIPMENT_DURABILITY]?.currentValue,
        premium: this.equipmentData.premium,
        equipmentBonusData: this.equipmentData.equipmentBonusData,
      }
    },
    // format dat ( Lebo mame data jak u debilov kazde view inak) pre componetu attributov.
    formatAttributeData(): FormattedAttributesParametersData {
      return {
        equipment_attribute_bonus:
          (this.currentLevel.parameters as ItemParameters).item_attribute_bonus ??
          (this.currentLevel.parameters as EquipmentParameters).equipment_attribute_bonus.value,
        equipment_cash_bonus:
          (this.currentLevel.parameters as ItemParameters).item_money_bonus ??
          (this.currentLevel.parameters as EquipmentParameters).equipment_cash_bonus.value,
        equipment_tp_bonus:
          (this.currentLevel.parameters as ItemParameters).item_tp_points_bonus ??
          (this.currentLevel.parameters as EquipmentParameters).equipment_tp_bonus.value,
        equipment_durability: this.currentLevel.parameters[EQUIPMENT_DURABILITY]?.value,
      }
    },
    getBorder(): string {
      if (this.repairInProgress) return 'not-origin-border box-border-2'
      if (this.isUpgrading || this.isDelivering) return 'not-origin-border box-border-3'
      if (this.isDamaged) return 'not-origin-border box-border-4'
      return 'not-origin-border box-border-1'
    },
    getProcessStyle(): string {
      if (this.repairInProgress) return 'green'
      if (this.isUpgrading || this.isDelivering) return 'yellow'
      return ''
    },
    getProcessColor(): string {
      if (this.repairInProgress) return 'text-texts-standard-upgrade'
      if (this.isUpgrading || this.isDelivering) return 'text-texts-standard-important'
      return ''
    },
    canBeRepaired(): boolean {
      return (
        this.currentLevel.parameters[EQUIPMENT_DURABILITY].currentValue <
        this.currentLevel.parameters[EQUIPMENT_DURABILITY].value
      )
    },
    operationInProgress(): boolean {
      return !!this.ongoingOperation
    },
    repairInProgress(): boolean {
      return (
        this.operationInProgress &&
        this.ongoingOperation.itemId === this.equipmentData.id &&
        this.ongoingOperation.type === OPERATION_REPAIR
      )
    },
    repairTooltipText(): Nullable<string> {
      if (this.canBeRepaired && !this.operationInProgress) return null
      return this.$t(
        this.operationInProgress
          ? 'equipmentShop.anotherQuestIsActive'
          : 'equipmentShop.notDamaged',
      )
    },
    canStartRepairing(): boolean {
      return this.money.value >= this.currentLevel.repair.price
    },
    isButtonVisible(): boolean {
      return !this.equipmentInProgress && !this.isOffer
    },
    equipmentInRepair(): boolean {
      return (
        this.repairInProgress &&
        this.itemId &&
        !!this.ongoingOperation &&
        this.itemId === this.ongoingOperation.itemId
      )
    },
    equipmentInDelivery(): boolean {
      return (
        this.isDelivering &&
        this.itemId &&
        !!this.ongoingOperation &&
        this.itemId === this.ongoingOperation.itemId
      )
    },
    equipmentInUpgrade(): boolean {
      return this.isUpgrading && this.itemId && !!this.ongoingOperation
    },
    equipmentInProgress(): boolean {
      return this.equipmentInRepair || this.equipmentInDelivery || this.equipmentInUpgrade
    },
    setProcessText(): string {
      if (!this.ongoingOperation?.type) return ''
      return this.$t(OPERATION_MAPPING[this.ongoingOperation.type])
    },
  },
  methods: {
    ...mapActions(useEquipmentStore, ['repairItem']),
    formatTime,
    async repair(): Promise<void> {
      if (!this.canStartRepairing) return

      this.showRepair = false

      this.$emit(
        'setOperation',
        this.equipmentData.id,
        this.currentLevel.repair.time,
        OPERATION_REPAIR,
        this.currentLevel.repair.speedup,
        this.equipmentData.name,
      )
      await this.repairItem(this.equipmentData.id)

      setTimeout(
        (): void => {
          this.$emit('loadItemDetail')
        },
        this.isEffectActive(GAME_PASS_INSTANT_EQUIPMENT_REPAIR) ? this.currentLevel.repair.time : 0,
      )
    },
    async prolongEquipment(): Promise<void> {
      this.showProlongPopup = false
      try {
        await this.$axios.patch<{}, true>(shopPremiumEquipmentProlong, {
          item_id: this.equipmentData.id,
        })
        this.$emit('loadItemDetail')
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async reloadItemPremium(): Promise<void> {
      try {
        const premiumItems = await this.$axios.get<{}, EquipmentPremiumOffersApiResponse>(
          `${shopAvailablePremiumItemEndPoint}${this.disciplineId}`,
        )
        if (!premiumItems.availableOffers) {
          this.$router.push({
            name: this.$getWebView('Equipment'),
            params: { id: this.disciplineId },
          })
          return
        }

        this.$emit('loadItemDetail')
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/equipment-icons.scss';
@import '@/assets/styles/components/icons/stars-icons.scss';

.eq-leftside-panel {
  margin-top: 2.188rem;
  width: 33.125rem;
  height: 38.438rem;
  background-image: url($path-equipment + 'BG-LEFT-BOX-CONTENT.avif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @if $isWsm {
    &:not(:not-origin-border) {
      border: 0.063rem solid #5ba3dc;
    }
    background-color: #184361;
  }
  @if $isSsm {
    &:not(:not-origin-border) {
      border: 0.063rem solid #4c648f;
    }
    background-color: #232a40;
  }
  &--bold-border {
    &-green {
      @if $isWsm {
        border: 0.125rem solid rgb(63, 228, 67);
      }
      @if $isSsm {
        border: 0.125rem solid #0ee7cc;
      }
    }

    &-yellow {
      @if $isWsm {
        border: 0.125rem solid rgb(250, 221, 30);
      }
      @if $isSsm {
        border: 0.125rem solid #feb942;
      }
    }

    .eq-leftside-panel-header {
      width: 32.873rem;
    }
  }

  &--mobile {
    border: 0.125rem solid #5ba3dc;

    .eq-leftside-panel-header {
      width: 32.873rem;
      left: 0;
    }

    .eq-leftside-panel-damaged-equipment {
      width: calc(100% - 0.125rem);
      left: 0.125rem;
    }
  }

  &.premium {
    background: url($path-equipment + 'table-premium.avif') center no-repeat;
    background-size: contain;
    border: 0;
    width: 33.125rem;
    height: 38.375rem;

    &::before,
    &::after {
      display: none;
    }
  }

  &-process {
    z-index: 1;
    @if $isWsm {
      background: rgba(7, 40, 68, 0.7);
    }
    @if $isSsm {
      background: rgba(59, 67, 74, 0.5);
    }

    &-msg {
      position: absolute;
      bottom: 1.5rem;

      &.green {
        @if $isWsm {
          background: rgba(63, 228, 67, 0.2);
          border-left: 0.125rem solid #85ff91;
          border-right: 0.125rem solid #85ff91;
        }
        @if $isSsm {
          background: rgba(#0ee7cc, 0.2);
          border-left: 0.125rem solid #0ee7cc;
          border-right: 0.125rem solid #0ee7cc;
        }
      }

      &.yellow {
        @if $isWsm {
          background: rgba(250, 221, 30, 0.2);
          border-left: 0.125rem solid #fadd1e;
          border-right: 0.125rem solid #fadd1e;
        }
        @if $isSsm {
          background: rgba(#feb942, 0.2);
          border-left: 0.125rem solid #feb942;
          border-right: 0.125rem solid #feb942;
        }
      }
    }
  }

  &-damaged-equipment {
    width: calc(100% - 0.063rem);
    height: 11.5rem;
    position: absolute;
    top: 5rem;
    left: 0.063rem;
    background: rgba(8, 22, 40, 0.9);

    &-msg {
      background: rgba(232, 20, 20, 0.2);
      border-left: 0.125rem solid #fc8a76;
      border-right: 0.125rem solid #fc8a76;
    }
  }

  &-repairing,
  &-damaged-equipment {
    &-msg {
      min-width: 19.5rem;
      height: 3.063rem;
      margin: 0 auto;
    }
  }

  &-header {
    position: relative;
    width: 99%;
    height: 10.563rem;
    left: 0.063rem;
    @if $isWsm {
      background: rgba(14, 38, 55, 0.4);
      box-shadow: inset 0 0 1.875rem rgba(2, 2, 2, 0.6);
    }
    @if $isSsm {
      box-shadow: inset 0 -0.188rem 0.125rem 0 rgba(0, 0, 0, 0.28);
      background-color: #1d273a;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 -0.188rem 0.125rem 0 rgba(0, 0, 0, 0.1);
    }

    &-stars {
      width: 27.6875rem;
      height: 5.0625rem;
    }

    .stars-icon__wrapper {
      width: calc(100% - 5.25rem);
      justify-content: space-between;
      :deep(.icon-star-full-80) {
        margin: 0 0.2rem;
      }
    }

    &.premium {
      background: none;
      box-shadow: none;
    }
  }

  &-section {
    height: 28.875rem;

    &__button {
      width: calc(100% - 2.5rem);
    }

    .bonuses {
      width: 28.6875rem;
      margin: 1rem auto;
    }

    .vitality-text {
      width: 28.6875rem;
      margin-top: 3rem;
      margin-bottom: 1.5rem;

      &:before {
        content: '';
        position: absolute;
        width: 20.5625rem;
        height: 0.25rem;
        left: 50%;
        top: -1rem;
        transform: translate(-50%);
        background: linear-gradient(
          to right,
          rgba(251, 227, 110, 0),
          #fbe36e,
          #fbe36e,
          rgba(251, 227, 110, 0)
        );
      }
    }

    .repair {
      position: absolute;
      left: 50%;
      transform: translate(-50%) $skewX-value;

      &:active {
        transform: translate(-50%) scale(0.95) $skewX-value !important;
      }
    }
  }

  .danger {
    color: theme('colors.texts.standard.danger');
  }

  .lock-button {
    opacity: 0.5;
  }
}

.repairs {
  position: relative;
  width: 62.375rem;
  height: 27.9375rem;

  &-container {
    &:before {
      content: '';
      position: absolute;
      width: 32.438rem;
      height: 14.25rem;
      top: -2.563rem;
      left: 50%;
      border-radius: 35rem;
      filter: blur(6rem);
      transform: translateX(-50%);
      z-index: -1;
      @if $isWsm {
        background: #366885;
      }
      @if $isSsm {
        background: rgba(#fff, 0.3);
      }
    }

    .logo {
      margin: 0 auto;
      width: 5rem;
      height: 5rem;
      margin-top: 1.875rem;
    }

    &-stars {
      margin-top: 1.5625rem;
      margin-bottom: 1.438rem;

      .repair-stars {
        margin-right: -1.8rem;
      }
    }

    .life {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 33.0625rem;
        height: 0.125rem;
        left: 50%;
        bottom: -0.625rem;
        transform: translate(-50%);
        background: linear-gradient(
          to right,
          transparent 10%,
          #154e79 25%,
          #ffffff 50%,
          #154e79 75%,
          transparent 90%
        );
      }
    }

    &-text {
      margin-top: 2.8125rem;
    }

    .eq-arrow {
      width: 0;
      height: 0;
      border-top: 0.625rem solid transparent;
      border-bottom: 0.625rem solid transparent;
      border-left: 1.0625rem solid #34d359;
      transform: rotate(-120deg);
      margin-top: -0.4rem;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  &-footer {
    position: absolute;
    display: flex;
    width: 100%;
    height: 6.875rem;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    bottom: 0;
    @if $isWsm {
      background: #154e79;
    }
    @if $isSsm {
      background: linear-gradient(to right, #1d1f2c, #2e3c63, #1d1f2c);
    }

    .price {
      margin-right: 2.5rem;
    }
  }

  &.premium {
    background: url($path-equipment + 'table-premium.avif') center no-repeat;
    background-size: cover;
  }
}

.premium-timer {
  width: 20rem;
  margin: 0 auto;
}

.circle-stack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0.625rem 0;
}

.prolong-btn {
  position: absolute;
  right: 0;
  top: 0.2rem;
}

.prolong-popup {
  height: 22rem;
}

#btn-prolong-equip {
  margin-bottom: 2rem;
}
</style>
