export const CHANGE_SCENE = 'changeScene'
export const LOAD_CLUBS_DATA = 'loadClubsData'
export const LOAD_EVENT_ASSETS = 'loadEventsAssets'
export const CREATE_EVENT_BUILDING = 'createEventBuilding'
export const DESTROY_EVENT_BUILDING = 'destroyEventBuilding'
export const SET_MAIN_MAP_REWARDED_ITEMS_TIMER = 'setMainMapRewardedItemsTimer'
export const SET_MAIN_MAP_NEW_REWARDED_ITEMS = 'setMainMapNewRewardedItems'
export const DESTROY_CONNECT_ACCOUNT_OSRA = 'destroyConnectAccountOsra'
export const CLAIMED_ITEM = 'claimedItem'
export const RELOAD_BUILDINGS = 'reloadBuildings'
export const SET_BUBBLE_NOTIFICATIONS = 'setBubbleNotifications'
export const SHOW_MAP_TUTORIAL = 'showMapTutorial'
export const HIDE_MAP_TUTORIAL = 'hideMapTutorial'
export const SET_BUILDINGS_TITLES_VISIBILITY = 'setBuildingsTitlesVisibility'
export const SET_CLUB_CHAMPIONSHIP_LABEL_INFO = 'setClubChampionshipLabelInfo'
export const MANAGE_INDICATOR_VISIBILITY = 'manageIndicatorVisibility'
export const MANAGE_PROGRESS_TIMER_VISIBILITY = 'manageProgressTimerVisibility'
export const REFRESH_PROGRESS_TIMERS = 'refreshProgressTimers'
export const CLEAR_AND_HIDE_TIMER = 'clearAndHideTimer'
export const SET_PREMIUM_NOTIFICATION = 'setPremiumNotification'
export const RELOAD_TEXTS = 'reloadTexts'
export const ZOOM_IN = 'zoomin'
export const ZOOM_OUT = 'zoomout'
export const ZOOM_RESET = 'zoomreset'
export const SET_BUILDING_REWARDS = 'set_building_rewards'
export const SHOW_WEATHER = 'showWeather'
export const HIDE_WEATHER = 'hideWeather'
export const CREATE_BOARD_GAMES_EVENT_OSRA = 'createBoardGamesEventOsra'
export const DESTROY_BOARD_GAMES_EVENT_OSRA = 'destroyBoardGamesEventOsra'
export const CREATE_PIGGY_BANK_OSRA = 'createPiggyBankOsra'
export const DESTROY_PIGGY_BANK_OSRA = 'destroyPiggyBankOsra'
export const CREATE_SPECIAL_TOURNAMENT_OSRA = 'createSpecialTournamentOsra'
export const DESTROY_SPECIAL_TOURNAMENT_OSRA = 'destroySpecialTournamentOsra'
export const RESUME_SCENE = 'RESUME_SCENE'
export const PAUSE_SCENE = 'PAUSE_SCENE'
