<template>
  <div class="map-task-tracker-body flex flex-col">
    <div
      v-for="(quest, index) in quests"
      :key="index"
      class="map-task-tracker-body-item flex items-center"
      :class="quest.isActive ? 'justify-center is-open' : 'justify-start'"
    >
      <div
        class="map-task-tracker-body-item-box flex flex-col items-center pointer-events-auto"
        :class="[
          { 'map-task-tracker-body-item-box-active': quest.isActive },
          { 'map-task-tracker-body-item-box-done': isQuestRewardClaimable(quest) },
          { 'map-task-tracker-body-item-box-second': quest.type === 'career_task' },
        ]"
        @click="clickOnBranchBox(quest, index)"
      >
        <app-discipline-icon
          v-if="quest.disciplineId > 0"
          :size="70"
          :discipline-id="quest.disciplineId"
          :is-transparent-bg="true"
          :has-tooltip="false"
          class="discipline-icon--shadowless !border-transparent"
        />
        <div
          v-if="quest.disciplineId === 0"
          :class="{ 'icon-career-white': $isWsm, 'icon-career-gold': $isSsm }"
        />
        <app-progress-bar
          v-if="!quest.parameters?.boss_fight"
          class="m-auto"
          :bar-height="0.5"
          :bar-width="4.5"
          :actual="quest.progress.currentValue"
          :goal="quest.progress.targetValue"
        />
        <div
          v-if="quest.parameters?.boss_fight"
          class="flexing map-task-tracker-body-item-box-stars"
        >
          <div
            v-for="star in 3"
            :key="star"
            :class="
              star <= quest.progress.currentValue ? 'icon-star-full-24' : 'icon-star-empty-24'
            "
          />
        </div>
      </div>
      <arrow-animation
        position="left"
        border="box"
        :tutorial="isTutorialStep"
        add-class="quest-arrow"
      >
        <div
          v-show="quest.isActive"
          class="map-task-tracker-body-item-text flex items-center justify-between cursor-pointer pointer-events-auto"
          :class="{ 'map-task-tracker-body-item-text-done': isQuestRewardClaimable(quest) }"
          @click="isTutorial ? () => {} : goToQuest(quest)"
        >
          <p
            v-if="!isQuestRewardClaimable(quest)"
            class="map-task-tracker-body-item-text-content text-white text-26"
          >
            {{ formatCareerQuestText(quest) }}
          </p>
          <div
            v-if="isQuestRewardClaimable(quest)"
            class="map-task-tracker-body-item-text-content flex flex-col"
          >
            <p class="text-26 text-texts-standard-important font-bold">
              {{ $t('career.trackerTaskCompleted') }}
            </p>
            <p class="text-26 uppercase text-white">{{ $t('common.takeRewards') }}</p>
          </div>
          <div class="map-task-tracker-body-item-text-arrow-right" />
        </div>
      </arrow-animation>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { formatCareerQuestText, setTasksActiveStates } from '@/helpers'
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import { useTutorialStore } from '@/store/pinia/tutorialStore'

import type { PropType } from 'vue'
import type { TaskTrackerQuest } from '@/interfaces/Quest'

export default defineComponent({
  name: 'MapTaskTrackerBody',
  components: {
    ArrowAnimation,
  },
  props: {
    quests: {
      type: Array as PropType<TaskTrackerQuest[]>,
      default: () => [],
    },
  },
  emits: ['showArrowForExtend', 'showArrowForHide'],
  computed: {
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    isTutorialStep(): boolean {
      return this.actualStage?.name === 'careerMapNarrativeCareer'
    },
  },
  methods: {
    formatCareerQuestText,
    isQuestRewardClaimable(quest: TaskTrackerQuest): boolean {
      return quest.progress.currentValue === quest.progress.targetValue && !quest.isDone
    },
    clickOnBranchBox(quest: TaskTrackerQuest, index: number): void {
      quest.isActive = !quest.isActive

      setTasksActiveStates({ state: quest.isActive, index })

      const activeQuests = this.quests.filter((quest: TaskTrackerQuest): boolean => quest.isActive)
      const inactiveQuests = this.quests.filter(
        (quest: TaskTrackerQuest): boolean => !quest.isActive,
      )

      if (activeQuests.length === this.quests.length) {
        this.$emit('showArrowForHide')
      }

      if (inactiveQuests.length === this.quests.length) {
        this.$emit('showArrowForExtend')
      }
    },
    goToQuest(quest: TaskTrackerQuest): void {
      this.$router.push({
        name: this.$getWebView('CareerDetail'),
        params: {
          careerId: quest.careerId,
          id: quest.disciplineId,
          task: quest.parameters.id,
        },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';

.map-task-tracker-body {
  margin-top: 0.5rem;

  &-item {
    margin-bottom: 0.2rem;

    &-box {
      cursor: pointer;
      position: relative;
      width: 5.5rem;
      height: 5.5rem;
      border: 0.188rem solid #586b9d;
      padding-bottom: 0.3rem;

      @if $isWsm {
        background: linear-gradient(
          45deg,
          rgba(45, 109, 159, 0.8) 0%,
          rgba(79, 147, 184, 0.9) 100%
        );
      }

      @if $isSsm {
        background: linear-gradient(45deg, rgba(#3e4f79, 0.8) 0%, rgba(#697faf, 0.9) 100%);
      }

      &-second {
        @if $isWsm {
          background: linear-gradient(
            45deg,
            rgba(35, 52, 65, 0.8) 0%,
            rgba(55, 127, 184, 0.8) 100%
          );
        }

        @if $isSsm {
          background: linear-gradient(
            45deg,
            rgba(52, 65, 93, 0.85) 0%,
            rgba(39, 50, 73, 0.85) 100%
          );
        }
      }

      &-done {
        @if $isWsm {
          border: 1px solid #fadd1e;
          background: linear-gradient(45deg, rgba(14, 138, 8, 0.8) 0%, rgba(73, 172, 46, 0.8) 100%);
        }
        @if $isSsm {
          border: 1px solid #ffaa3c;
          background: linear-gradient(
            45deg,
            rgba(14, 231, 204, 0.85) 0%,
            rgba(8, 202, 178, 0.85) 100%
          );
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 1.5rem;
        right: -2.1rem;
        border-width: 1rem;
        border-style: solid;
        transform: rotate(180deg);

        @if $isWsm {
          border-color: transparent #75b6e7 transparent transparent;
        }

        @if $isSsm {
          border-color: transparent #586b9d transparent transparent;
        }
      }

      &-active {
        @if $isWsm {
          border: 0.188rem solid #ffe719;
        }

        @if $isSsm {
          border: 0.188rem solid theme('colors.texts.standard.important');
        }

        &::before {
          @if $isWsm {
            border-color: transparent #ffe719 transparent transparent;
          }

          @if $isSsm {
            border-color: transparent
              theme('colors.texts.standard.important')
              transparent
              transparent;
          }
        }
      }

      &-done {
        &::before {
          border-color: transparent;
        }
      }

      &-stars {
        height: 0.1rem;
        gap: 0.25rem; // Consistent spacing between dual stars

        & > div {
          // Handling inter-item space when we have filled and unfilled stars in task tracking
          margin: 0 !important;
        }
      }
    }

    &-text {
      width: 30rem;
      height: 5.25rem;
      text-align: left;
      padding-left: 2rem;
      line-height: 1.1;
      @if $isWsm {
        background: linear-gradient(
          90deg,
          rgba(11, 30, 55, 0.9) 0%,
          rgba(11, 30, 55, 0.7) 80%,
          rgba(21, 78, 121, 0) 100%
        );
      }

      @if $isSsm {
        background: linear-gradient(
          90deg,
          rgba(39, 50, 73, 0.9) 0%,
          rgba(#34415d, 0.7) 80%,
          rgba(21, 78, 121, 0) 100%
        );
      }

      &-done {
        @if $isWsm {
          background: linear-gradient(
            90deg,
            rgba(14, 138, 8, 0.9) 0%,
            rgba(14, 138, 8, 0.7) 70%,
            rgba(73, 172, 46, 0) 100%
          );
        }
        @if $isSsm {
          background: linear-gradient(
            90deg,
            rgba(52, 144, 115, 0.9) 0%,
            rgba(35, 159, 142, 0.7) 70%,
            rgba(35, 159, 142, 0) 100%
          );
        }
      }

      &-content {
        width: 75%;
      }

      &-arrow-right {
        content: '';
        border-width: 1rem;
        border-style: solid;
        transform: rotate(180deg);
        padding-left: 4rem;
        cursor: pointer;

        @if $isWsm {
          border-color: transparent #d9ecff transparent transparent;
        }

        @if $isSsm {
          border-color: transparent #586b9d transparent transparent;
        }
      }
    }

    &.is-open .map-task-tracker-body-item-text-arrow-right {
      @if $isSsm {
        border-color: transparent #fff transparent transparent;
      }
    }
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!border-transparent {
  border-color: transparent !important;
}
</style>
