import { BUILDING_LOCKED_TOOLTIP_POSITION } from '@/map-phaser-new/constants'
import type { BuildingConfigInterface } from '@/map-phaser-new/interfaces'

export const laboratoryConfig: BuildingConfigInterface = {
  textureKey: 'buildings',
  frameKey: 'laboratory',
  routeConfig: {
    routeName: 'LabDisciplines',
    soundName: 'click-map-artefact',
    isRouteParamIdNeeded: true,
  },
  positionX: 1154,
  positionY: 1019,
  depth: 4,
  originX: 0,
  keyForTranslateTitle: 'map.labName',
  buildingTitleConfig: {
    positionX: 1299,
    positionY: 1257.5,
    depth: 7,
    textConfig: {
      originX: 0.5,
      fontName: 'Roboto Condensed',
      fontSize: '24px',
      fontWeight: 'normal',
      fontStyle: 'italic',
      fontColor: '#fff',
    },
    backgroundImageConfig: {
      textureName: 'common',
      frameNameRightSide: 'right_side',
      frameNameRightSideLocked: 'right_side_locked',
      originX: 0.5,
      widthCorrection: 100,
      heightCorrection: 10,
    },
  },
  progressTimerConfig: {
    textureKey: '',
    positionX: 1305,
    positionY: 988,
    depth: 6,
    htmlTextConfig: {
      fontName: 'Roboto Condensed',
      fontSize: '20px',
      fontWeight: 'normal',
      fontStyle: 'italic',
      fontColor: '#fff',
    },
    backgroundImageConfig: {
      textureName: 'common',
      originX: 0.5,
    },
    progressAssetsConfig: {
      progressBaseBackground: {
        progressBaseBackgroundYCorrection: 32,
        frame: 'progress_bar_base_slice',
        width: 270,
        height: 8,
      },
      progressBackground: {
        frame: 'info_bg_red',
      },
      headingText: {
        headingTextYCorrection: -3,
        name: 'headingText',
        color: '#feb942',
      },
      countdownText: {
        name: 'countdownText',
      },
      progressFill: {
        name: 'progressFill',
        frame: 'progress_bar_fill_slice',
        width: 4,
        height: 8,
      },
    },
    animationConfig: {
      width: 270,
      ease: 'sine.inout',
      yoyo: false,
    },
  },
  outlineConfig: {
    framePostFix: '_outline',
    depth: 6,
    originX: 0,
    positionByLevel: {
      '1': { positionX: 1154, positionY: 1019 },
    },
    textureKey: 'buildingsOutlines',
  },
  inputConfig: {
    useHandCursor: true,
  },
  buildingLockedConfig: {
    textureKey: 'lockedBuildings',
    framePostfix: '_locked',
    lockImageTextureKey: 'lock',
    positionX: 1316,
    positionY: 1136,
    lockImageDepth: 6,
    depth: 5,
    tooltipConfig: {
      tooltipPosition: BUILDING_LOCKED_TOOLTIP_POSITION.Top,
      tooltipTextureHeight: '213px',
      tooltipTextureWidth: '444px',
      depth: 10,
      titleConfig: {
        originX: 0.5,
        fontSize: '30px',
        fontName: 'Roboto Condensed',
        fontWeight: 'normal',
        fontStyle: 'italic',
        fontColor: '#fff',
      },
      subtitleConfig: {
        originX: 0.5,
        originY: 0.5,
        fontName: 'Roboto Condensed',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStyle: 'italic',
        fontColor: '#fff',
        replaceFontColor: '#fec742',
        tooltipSubtitleTranslateKey: 'common.mainCareerTaskRequired',
        tooltipSubtitleReplaceFlag: '%s',
      },
      tooltipHideDelay: 2000,
      tooltipLeftTextureName: 'building_tooltip_left',
      tooltipRightTextureName: 'building_tooltip_right',
      tooltipTopTextureName: 'building_tooltip_top',
    },
  },
}
