<template>
  <div
    v-if="!isContentLoading"
    class="main-duel-main-rightbox bordered-lg"
    :class="{ 'is-event-view': isEventView }"
    :style="{
      background:
        'url(' + pathImages + 'disciplines/' + activeDisciplineId + '-arena.avif) center no-repeat',
      backgroundSize: 'cover',
    }"
  >
    <div>
      <div
        v-if="isTournamentDiscipline2 && specialTournamentData"
        class="tournaments-early-access-box flex flex-col items-end"
      >
        <div
          class="tournaments-early-access-box__info-label flexing"
          :class="{
            'text-texts-standard-default': $isWsm,
            'text-texts-standard-dark': $isSsm,
          }"
        >
          <!-- TODO localization text -->
          <span class="text-22 uppercase">{{ 'Sponsored' }}</span>
          <tippy theme="WSM" placement="top" max-width="35rem" class="bottom-4 right-4">
            <app-icon icon-name="info-50" />
            <template #content>
              <div class="tooltip-content text-texts-standard-default text-30">
                <p>
                  {{
                    $replacePlaceholder(
                      $replacePlaceholder(
                        $replacePlaceholder(
                          $t('tournaments.beatTimeTooltip'),
                          '{time}',
                          formatResult(
                            specialTournamentData.required_result,
                            DISCIPLINE_ID.biathlon,
                          ),
                        ),
                        '{seriesname}',
                        specialTournamentData.name,
                      ),
                      '{partner}',
                      'IBU', // TODO localization text 'IBU'
                    )
                  }}
                </p>
              </div>
            </template>
          </tippy>
        </div>
        <div class="tournaments-early-access-box__content">
          <div class="tournaments-early-access-box__content-header flexing flex-col">
            <div
              class="tournaments-early-access-box__content-header-logo"
              :style="{
                background: `url(${pathImages}tournaments/logos/light/${specialTournamentData.icon}.avif) center no-repeat`,
                backgroundSize: 'contain',
              }"
            />
            <div class="tournaments-early-access-box__content-header-title flexing gap-3">
              <app-icon icon-name="lock-xsm" />
              <span class="text-28 font-bold text-texts-standard-important">
                {{ specialTournamentData.name }}
              </span>
            </div>
          </div>
          <div
            class="tournaments-early-access-box__content-time flexing text-28 text-texts-standard-default"
          >
            {{
              $replacePlaceholder(
                $t('tournaments.beatTime'),
                '{time}',
                formatResult(specialTournamentData.required_result, DISCIPLINE_ID.biathlon),
              )
            }}
          </div>
        </div>
      </div>
      <event-discipline-badge
        v-else
        :active-discipline-id="activeDisciplineId"
        :is-event-view="isEventView"
      />
    </div>
    <div class="main-duel-main-rightbox-header flex items-center">
      <div class="main-duel-main-rightbox-header-logo">
        <app-discipline-icon :discipline-id="activeDisciplineId" />
      </div>
      <div class="main-duel-main-rightbox-header-info">
        <div class="main-duel-main-rightbox-header-info-title text-texts-standard-additional">
          {{ $translateDiscipline(activeDisciplineId) }}
        </div>
        <div class="main-duel-main-rightbox-header-info-text">
          {{ $t('common.personalBest') + ':' }}
          <span class="text-texts-standard-important">
            {{
              formatResult(activeDiscipline.mechanicStats?.bestScore, activeDiscipline.id) ?? '-'
            }}
          </span>
        </div>
      </div>
      <div class="main-duel-main-rightbox-header-ranking flexing">
        <span
          class="main-duel-main-rightbox-header-ranking-text text-texts-standard-additional text-28 uppercase font-bold"
        >
          <short-text
            class="default-font-size"
            :text="`${$t('rankingsGroup.rankingPoints')}:`"
            first-custom="text-24"
          />
        </span>
        <div class="main-duel-main-rightbox-header-ranking-points flexing">
          <div class="main-duel-main-rightbox-header-ranking-points-count">
            <p class="text-texts-standard-default text-60 font-bold">
              {{ activeDiscipline.userStats?.rankingPoints }}
            </p>
          </div>
          <app-main-icon :icon-size="48" icon-name="ranking_points" />
        </div>
      </div>
    </div>

    <div class="main-duel-main-rightbox-footer absolute">
      <reward-icon class="absolute" :position="1" />
      <div class="main-duel-main-rightbox-footer-header">
        <div
          v-if="activeDiscipline.rewards"
          class="main-duel-main-rightbox-footer-header-rewards flexing relative"
        >
          <p class="text-34 text-texts-standard-default">{{ isGroupB ? '' : '(' }}</p>
          <template v-for="reward in activeDiscipline.rewards[0]" :key="reward.name">
            <span class="flex relative mx-4">
              <p class="text-34 text-texts-standard-default">
                {{ reward?.value }}
              </p>
              <app-icon
                v-if="reward.name === EVENT_REPUTATION"
                :icon-name="reward.name"
                :tooltip-text="$te('reputation')"
                class="icon-in-arena ml-2"
              />
              <app-main-icon v-else :icon-size="48" :icon-name="reward.name" />
              <not-enough-benefits-for-live
                v-if="reward.name === MONEY && !enoughCashBenefits && !isGroupB"
                type="cash"
                :discipline-id="activeDisciplineId"
                :live-value="
                  activeDiscipline?.rewardsWithoutBenefit[0].find(
                    (reward: Reward): boolean => reward.name === MONEY,
                  )?.value
                "
                :simulation-value="reward?.value"
              />
            </span>
          </template>
          <p v-if="!isGroupB" class="text-34 text-texts-standard-default flexing">
            ) x <app-main-icon :icon-size="48" icon-name="starts" />
          </p>
        </div>
        <app-icon
          icon-name="question-60"
          class="icon-info main-duel-main-rightbox-footer-header-info"
          @click="showRewardsPopUp = true"
        />
        <info-popup
          v-if="showRewardsPopUp"
          :popup-title="$t('common.allRewards')"
          @close="showRewardsPopUp = false"
        >
          <arena-rewards
            :discipline-id="activeDisciplineId"
            :discipline-title="$translateDiscipline(activeDisciplineId)"
            :rewards="activeDiscipline.rewards"
            :rewards-base="!enoughCashBenefits ? activeDiscipline.rewardsWithoutBenefit : null"
            :starts-base-number="baseStartsCost"
            :starts-live-number="liveStartsCost"
          />
        </info-popup>
      </div>
      <div class="main-duel-main-rightbox-footer-header-buttons flexing">
        <arrow-animation
          v-if="!(isGroupB && !isTutorial)"
          :tooltip-content="$t('tutorial.firstDuelLiveInfo')"
          position="right"
          border="button"
          :tutorial="isTutorialLiveDuelClick"
        >
          <app-multi-button
            v-tippy="getTippyContent(true)"
            btn-id="arena-start-live"
            btn-type="secondary"
            :btn-text="$t('button.start')"
            btn-size="sm"
            multi-width="25.125rem"
            class="inline-flex align-top"
            :class="{ 'button-anim': isTutorialLiveDuelClick }"
            :disabled="disabledActionButton"
            :loading="isMinigame || isButtonDisabled === PlayButtonLock.Live"
            :is-cool-down="isCoolDown"
            @click="isCoolDown ? null : onButtonClick(true)"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">
                {{ liveStartsCost }}
              </span>
              <app-main-icon :icon-size="48" :icon-name="'starts'" />
            </template>
          </app-multi-button>
        </arrow-animation>
        <arrow-animation
          :tooltip-content="$t('tutorial.firstDuelSimInfo')"
          position="right"
          border="button"
          :tutorial="isTutorialSimulationDuelClick"
        >
          <app-multi-button
            v-tippy="getTippyContent(false)"
            btn-id="arena-start-simulation"
            btn-type="primary"
            :btn-text="$t('button.simulate')"
            btn-size="sm"
            multi-width="25.125rem"
            class="inline-flex align-top"
            :class="{ 'button-anim': isTutorialSimulationDuelClick }"
            :disabled="disabledActionButton"
            :loading="isSimulation || isButtonDisabled === PlayButtonLock.Simulation"
            @click="isSimulation ? null : onButtonClick(false)"
          >
            <template #rightPart>
              <span class="text-texts-standard-default">
                {{ baseStartsCost }}
              </span>
              <app-main-icon :icon-size="48" :icon-name="'starts'" />
            </template>
          </app-multi-button>
        </arrow-animation>
      </div>
      <parameter-popup v-if="isPopupOpen" :parameter="STARTS" @close="isPopupOpen = false" />
    </div>
  </div>

  <component-loading :is-loading="isContentLoading" width="55.625rem" type="secondary" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import {
  CASH,
  EVENT_REPUTATION,
  MECHANIC_TOURNAMENTS_DISCIPLINE_2,
  MONEY,
  Multiplier,
  RANKING_POINTS,
  STARTS,
  pathImages,
  DISCIPLINE_ID,
} from '@/globalVariables'
import { formatResult, rewardIconsMapping } from '@/helpers'
import ArenaRewards from '@/components/Arena/ArenaRewards.vue'
import RewardIcon from '@/components/Arena/RewardIcon.vue'
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import NotEnoughBenefitsForLive from '@/components/Benefits/NotEnoughBenefitsForLive.vue'
import EventDisciplineBadge from '@/components/Events/EventDisciplineBadge.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import ShortText from '@/components/ShortText.vue'
import { useDuelStore } from '@/store/pinia/arena/duelStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { PlayButtonLock } from '@/interfaces/Buttons'
import { ApiService } from '@/services/ApiService'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'

import type { PropType } from 'vue'
import type { Slots } from '../ArenaSharedInterface'
import type { Nullable } from '@/interfaces/utils'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type Reward from '@/interfaces/Reward'
import type { BenefitSlot } from '@/interfaces/Benefits'
import type { SpecialTournament } from '@/interfaces/responses/disciplines/DisciplinesApiResponses'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'

interface ComponentData {
  EVENT_REPUTATION: typeof EVENT_REPUTATION
  isButtonDisabled: PlayButtonLock
  isPopupOpen: boolean
  MONEY: typeof MONEY
  pathImages: typeof pathImages
  PlayButtonLock: typeof PlayButtonLock
  RANKING_POINTS: typeof RANKING_POINTS
  showRewardsPopUp: boolean
  STARTS: typeof STARTS
  DISCIPLINE_ID: typeof DISCIPLINE_ID
}

export default defineComponent({
  name: 'ArenaCompetitionsDisciplineDetail',
  components: {
    ArenaRewards,
    RewardIcon,
    ShortText,
    InfoPopup,
    ArrowAnimation,
    NotEnoughBenefitsForLive,
    EventDisciplineBadge,
    ParameterPopup,
  },
  props: {
    activeDisciplineId: {
      type: Number,
      default: 1,
      required: true,
    },
    benefitsSlots: {
      type: Object as PropType<Nullable<BenefitSlot['slots']>>,
      default: () => null,
    },
    isEventView: {
      type: Boolean,
      default: false,
    },
    multiplierContext: {
      type: String as PropType<Multiplier>,
      default: Multiplier.Arena,
      validator: (value: string): boolean => {
        return Object.values(Multiplier).includes(value as Multiplier)
      },
    },
  },
  emits: ['playDuel'],
  data(): ComponentData {
    return {
      EVENT_REPUTATION,
      isButtonDisabled: PlayButtonLock.Unset,
      isPopupOpen: false,
      MONEY,
      pathImages,
      PlayButtonLock,
      RANKING_POINTS,
      showRewardsPopUp: false,
      STARTS,
      DISCIPLINE_ID,
    }
  },
  computed: {
    ...mapState(useDuelStore, {
      startsCostConfig: 'getStartsCostConfig',
    }),
    ...mapState(useUserStore, {
      activeMultiplicator: 'getActiveMultiplicator',
      isGroupB: 'isGroupB',
      isGroupCD: 'isGroupCD',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useCoreStore, {
      isSimulation: 'isSimulation',
      isMinigame: 'isMinigame',
    }),
    ...mapState(useResponseTaskStore, {
      userStarts: 'getStarts',
      getCoolDown: 'getCoolDown',
      hasMechanic: 'hasMechanic',
    }),
    ...mapState(useDisciplineStore, {
      activeDiscipline: 'getSpecificDiscipline',
      disciplinesMenuData: 'getDisciplinesMenuData',
    }),
    enoughCashBenefits(): boolean {
      if (!this.benefitsSlots) return true
      for (const value of Object.values(this.benefitsSlots)) {
        if (value) {
          const filteredSlot = Object.values(value).find(
            (slot: Slots[0]): boolean => slot?.benefit_type === CASH,
          )
          if (filteredSlot) {
            return (
              Number(filteredSlot?.amount) >= this.liveStartsCost ||
              this.baseStartsCost > Number(filteredSlot.amount)
            )
          }
        }
      }
      return true
    },
    liveStartsCost(): number {
      return this.startsCostConfig.live * this.activeMultiplicator(this.multiplierContext).value
    },
    baseStartsCost(): number {
      return this.startsCostConfig.base * this.activeMultiplicator(this.multiplierContext).value
    },
    isContentLoading(): boolean {
      return this.activeDiscipline == null || this.activeDiscipline.rewards == null
    },

    isTutorialLiveDuelClick(): boolean {
      return this.actualStageData?.name === 'returnFromLiveDuel'
    },
    isTutorialSimulationDuelClick(): boolean {
      return this.actualStageData?.secondStage === 'simulationDuelClick'
    },
    isCoolDown(): boolean {
      return this.isGroupCD && this.getCoolDown > 0
    },
    disabledActionButton(): boolean {
      // Final trick is for initial tutorial specifics In case any
      // other tutorial will make people loose starts this has to be amended
      return (
        this.isSimulation ||
        this.isButtonDisabled !== PlayButtonLock.Unset ||
        (this.isTutorial && !this.isTutorialSimulationDuelClick)
      )
    },
    specialTournamentData(): SpecialTournament {
      const menuDataByDisciplineId = this.disciplinesMenuData?.find(
        (discipline: DisciplineMenuData): boolean => discipline.id === this.activeDisciplineId,
      )
      return menuDataByDisciplineId?.specialTournament ?? null
    },
    isTournamentDiscipline2(): boolean {
      return (
        this.$isWsm &&
        this.activeDisciplineId === DISCIPLINE_ID.biathlon &&
        !this.hasMechanic(MECHANIC_TOURNAMENTS_DISCIPLINE_2)
      )
    },
  },
  watch: {
    isMinigame(newVal: boolean): void {
      if (newVal) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
    isSimulation(newVal: boolean): void {
      if (newVal) return
      this.isButtonDisabled = PlayButtonLock.Unset
    },
  },
  methods: {
    rewardIconsMapping,
    formatResult,
    onButtonClick(isLive: boolean): void {
      if (this.isPlayDisabled(isLive)) {
        this.isPopupOpen = true
        return
      }

      if (this.isButtonDisabled !== PlayButtonLock.Unset || this.isSimulation || this.isMinigame)
        return

      if (!this.isPlayDisabled(isLive))
        this.isButtonDisabled = isLive ? PlayButtonLock.Live : PlayButtonLock.Simulation

      ApiService.$clear()
      this.$debounce((): void => {
        this.$emit('playDuel', isLive)
      })
    },
    isPlayDisabled(isLive: boolean): boolean {
      if (isLive) {
        return this.userStarts.value < this.liveStartsCost
      }
      return this.userStarts.value < this.baseStartsCost
    },
    getTippyContent(isLive: boolean): {
      theme: string
      content: string
      placement: string
      textAlign: string
      appendTo: string
    } {
      let content = this.isPlayDisabled(isLive) ? this.$t('arena.notEnoughStarts') : ''
      if (isLive && !content.length && this.isCoolDown) {
        content = this.$t('common.cooldown')
      }
      return {
        theme: 'WSM',
        content: content,
        placement: 'top',
        textAlign: 'center',
        appendTo: 'parent',
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.main-duel-main-rightbox {
  &.is-event-view {
    border-style: solid;
    border-width: 0.125rem;
    border-image-source: linear-gradient(to top, #5ba3dc, #bfe3ff, #5ba3dc);
    border-image-slice: 1;
    background-color: #236d9b;
  }

  .tournaments-early-access-box {
    position: absolute;
    top: 9.5rem;
    right: 0;

    &__info-label {
      position: relative;
      width: 13rem;
      height: 2rem;
      gap: 0.5rem;
      padding: 0 0.688rem;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 0;
        clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
      }

      @if $isWsm {
        &::before {
          background-image: linear-gradient(to right, #2bc82f, #0c9e0c);
        }
      }

      @if $isSsm {
        &::before {
          background-image: linear-gradient(to right, #4affe8, #00d5ba);
        }
      }

      & * {
        z-index: 1;
      }
    }

    &__content {
      position: relative;
      width: 19.375rem;
      height: 10.5rem;

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        height: 0.125rem;
        background-image: linear-gradient(
          to right,
          transparent 0,
          rgba(185, 223, 253, 0.5) 10%,
          rgba(185, 223, 253, 0.8),
          rgba(185, 223, 253, 0.8)
        );
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      &-header {
        height: 7.063rem;
        margin-top: 0.125rem;
        gap: 0.563rem;
        padding-left: 1rem;

        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent 0,
            rgba(30, 93, 137, 0.5) 20%,
            rgba(25, 89, 127, 0.8),
            rgba(12, 74, 118, 0.8)
          );
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent 0,
            rgba(60, 64, 90, 0.5) 10%,
            rgba(38, 44, 59, 0.8),
            rgba(38, 42, 50, 0.8)
          );
        }

        &-logo {
          width: 5.5rem;
          height: 3.063rem;
          margin-top: 0.5rem;
        }
      }

      &-time {
        height: 3.188rem;
        padding-left: 1rem;

        @if $isWsm {
          background-image: linear-gradient(
            to right,
            transparent 0,
            rgba(206, 15, 15, 0.5) 20%,
            rgba(206, 15, 15, 0.8),
            rgba(149, 10, 10, 0.8)
          );
        }

        @if $isSsm {
          background-image: linear-gradient(
            to right,
            transparent 0,
            rgba(206, 15, 15, 0.5) 20%,
            rgba(206, 15, 15, 0.8),
            rgba(149, 10, 10, 0.8)
          );
        }
      }
    }
  }
}
</style>
