import type { AnimatedObjectWithPathsInterface } from '@/map-phaser-new/interfaces'
import { FRAMES_ANIMATION_TYPES } from '@/map-phaser-new/constants'
import {
  clubBallonAnimations,
  clubHelicopterRoundAnimations,
} from './animations'
import {
  clubHelicopterRoundCheckpoints,
} from './checkPointsData'
import {
  clubBallonRightPath,
  clubBallonTopMiddlePath,
  clubHelicopterRoundPath,
} from './pathsData'

export const animatedObjectsWithPathsConfig: AnimatedObjectWithPathsInterface[] = [
  {
    animationName: 'clubBallonTopMiddle',
    textureKey: 'animations',
    animationsData: clubBallonAnimations,
    pathsData: clubBallonTopMiddlePath,
    variations: ['v01', 'v02'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'clubBalloonRotate',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 100000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubBallonTopMiddle1',
        startDelay: 0,
        yoyoDelay: 20000,
      },
    ],
  },
  {
    animationName: 'clubBallonRight',
    textureKey: 'animations',
    pathsData: clubBallonRightPath,
    variations: ['v01'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'clubBalloonRotate',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 20000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubBallonRight1',
        startDelay: 0,
        yoyoDelay: 10000,
      },
    ],
  },
  {
    animationName: 'clubHelicopterRound',
    textureKey: 'animations',
    pathsData: clubHelicopterRoundPath,
    animationsData: clubHelicopterRoundAnimations,
    checkpointsData: clubHelicopterRoundCheckpoints,
    variations: ['v01'],
    framePrefix: 'WSM_helicopter_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 90000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubHelicopterRound1',
        startDelay: 1000,
      },
    ],
  },
]
