import type { SpecialTournamentOsraConfigInterface } from '@/map-phaser-new/interfaces'

export const specialTournamentOsra: SpecialTournamentOsraConfigInterface = {
  textureKey: 'ibu_logo',
  positionX: 1932,
  positionY: 1112,
  depth: 3,
  origin: 0.5,
  inputConfig: {
    useHandCursor: false,
  },
}
